<template>
    <div :class="className">
        <div class="inputwrapper">
            <v-textarea
                v-if="type == 'textarea'"
                v-model="inputText"
                :clearable="clearable"
                :required="required"
                :type="type"
                :label="label"
                :disabled="disabled"
                :placeholder="placeholder"
                :format="format"
                :autofocus="autofocus"
                :error-messages="errorMessages"
                :color="color"
                ref="input"
                hide-details
                :dense="dense"
                autocomplete="off"
                :loading="loading"
                @keydown.up="upInterceptor"
                @keydown.down="downInterceptor"
                @keydown.enter="enterInterceptor"
                @keydown="$emit('keydown', inputVal)"
                @focusout="focusOut"
                :max-width="maxWidth"
                :min-width="minWidth"
            />
            <input
                v-else-if="type == 'radio'"
                :id="name"
                :required="required"
                :type="type"
                :disabled="disabled"
                :color="color"
                :placeholder="placeholder"
                :autofocus="autofocus"
                :checked="inputVal == 'on'"
                :loading="loading"
                ref="input"
                @keydown.up="upInterceptor"
                @keydown.down="downInterceptor"
                @keydown.enter="enterInterceptor"
                @focusout="focusOut"
            />

            <v-text-field
                v-else
                :single-line="singleLine"
                v-model="inputText"
                :clearable="clearable"
                :type="inputType"
                :label="label + (required ? ' (*)' : '')"
                :disabled="disabled"
                :placeholder="placeholder"
                :format="format"
                :autofocus="autofocus"
                :color="color"
                :step="step"
                :max="max"
                :min="min"
                :dense="dense"
                :error-messages="errorMessages"
                :loading="loading"
                hide-details
                autocomplete="off"
                ref="input"
                :persistent-placeholder="persistentPlaceholder"
                @keydown.up="upInterceptor"
                @keydown.down="downInterceptor"
                @keydown.enter="enterInterceptor"
                @keydown="keydown"
                @focusout="focusOut"
                :max-width="maxWidth"
                :min-width="minWidth"
            />
            <span class="ml-2" v-if="label && type == 'checkbox'">
                {{ label }}
            </span>
        </div>
        <div v-if="errorMessages" class="alert alert-danger mt-2" role="alert">
            <div v-for="(error, index) in errorMessages" :key="index">
                {{ error }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Field",
    props: {
        value: {
            type: [String, Number, Boolean],
            required: false,
            default: null,
        },
        className: {
            type: String,
            default: "mb-3",
        },
        persistentPlaceholder: {
            type: Boolean,
            default: false,
        },
        singleLine: {
            type: Boolean,
            default: false,
        },
        placeholder: {
            type: String,
            default: "",
        },
        clearable: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        type: {
            type: String,
            required: false,
            default: "text",
        },
        min: {
            type: Number,
            required: false,
        },
        max: {
            type: Number,
            required: false,
        },
        errorMessages: {
            type: Array,
            required: false,
            default: null,
        },
        label: {
            type: String,
            required: false,
        },
        name: {
            type: String,
            required: false,
            default: null,
        },
        hint: {
            type: String,
            required: false,
            default: null,
        },
        required: {
            type: Boolean,
            required: false,
        },
        disabled: {
            type: Boolean,
            required: false,
        },
        format: {
            type: String,
            required: false,
        },
        autofocus: {
            type: Boolean,
            default: false,
        },
        step: {
            type: String,
            required: false,
        },
        color: {
            type: String,
            required: false,
        },
        dense: {
            type: Boolean,
            default: false,
        },
        minWidth: {
            type: Number,
            required: false,
        },
        maxWidth: {
            type: Number,
            required: false,
        },
    },
    data() {
        return {
            inputVal: this.value,
        };
    },
    computed: {
        tagname() {
            if (this.type == "textarea") {
                return "textarea";
            }
            return "input";
        },
        inputText: {
            get() {
                return this.value;
            },
            set(newValue) {
                if (newValue === "") {
                    this.inputVal = null;
                    return;
                }
                switch (this.type) {
                    case "integer":
                        this.inputVal = parseInt(newValue);
                        break;
                    case "float":
                        this.inputVal = parseFloat(newValue);
                        break;
                    default:
                        this.inputVal = newValue;
                }
            },
        },
        inputType() {
            switch (this.type) {
                case "integer":
                case "float":
                    return "number";
                default:
                    return this.type;
            }
        },
    },
    watch: {
        inputVal(inputVal) {
            this.$emit("input", this.inputVal === "" ? null : this.inputVal);
        },
        value(value) {
            this.inputText = value;
        },
    },
    mounted() {
        if (this.autofocus) {
            this.$refs.input.focus();
        }
    },
    methods: {
        keydown(inputVal) {
            this.$emit("keydown", inputVal);
        },
        focus() {
            this.$refs.input.focus();
        },
        focusOut() {
            this.$emit("input", this.inputVal === "" ? null : this.inputVal);
            this.$emit("focusout", this.inputVal === "" ? null : this.inputVal);
        },
        upInterceptor() {
            this.$emit("navigationUp");
        },
        downInterceptor() {
            this.$emit("navigationDown");
        },
        enterInterceptor() {
            this.$emit("navigationEnter");
        },
    },
};
</script>
