<template>
    <infinite-autocomplete
        v-model="selected"
        :disabled="disabled"
        :items="items"
        :loading="loading"
        :hasMore="hasMore"
        @menu-opened="onMenuOpened"
        @search-changed="onSearchChanged"
        @load-more="loadNextPage"
        @input="$emit('input', $event)"
        @select="$emit('select', $event)"
        :item-text="itemText"
        :label="labelText"
        :required="required"
        :autofocus="autofocus"
        :readonly="readonly"
        ref="search"
    >
        <template slot="item" slot-scope="{ item }">
            <slot name="item" :item="item">
                <v-list-item-title>
                    {{ item.name }}
                </v-list-item-title>
            </slot>
        </template>
    </infinite-autocomplete>
</template>

<script>
import axios from "@/plugins/axios";

export default {
    name: "ItemSearch",
    props: {
        // The base URL to fetch items from.
        baseUrl: {
            type: String,
            required: true,
        },
        // Additional GET parameters to include in the API request.
        additionalParams: {
            type: Object,
            default: () => ({}),
        },
        // Whether the component is disabled.
        disabled: {
            type: Boolean,
            default: false,
        },
        // v-model binding (selected item ID).
        value: {
            type: [String, Number],
            default: null,
        },
        // Label for the autocomplete.
        label: {
            type: String,
            default: "",
        },
        // Optional limit per request.
        limit: {
            type: Number,
            default: 20,
        },
        itemText: {
            type: Function,
            default: () => (item) => item.name,
        },
        display: {
            type: String,
            default: "default",
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        required: {
            type: Boolean,
            default: false,
        },
        autofocus: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            selected: this.value,
            items: [],
            loading: false,
            hasMore: true,
            offset: 0,
            search: "",
            fetchedOnce: false, // Flag to know if an initial fetch has occurred
        };
    },
    computed: {
        labelText() {
            return this.label || this.$translate("select_item");
        },
    },
    watch: {
        value(newVal) {
            this.selected = newVal;
            if (newVal != null && !this.items.find((item) => item.id === newVal)) {
                this.fetchItemDetail(newVal);
            }
        },
        baseUrl(newVal, oldVal) {
            if (newVal === oldVal) return;
            this.resetAndFetch();
        },
        additionalParams: {
            handler(newVal, oldVal) {
                if (JSON.stringify(newVal) === JSON.stringify(oldVal)) return;
                this.resetAndFetch();
            },
            deep: true,
        },
    },
    methods: {
        resetAndFetch() {
            // Reset the state and clear fetchedOnce flag.
            //this.selected = null;
            this.items = [];
            this.offset = 0;
            this.search = "";
            this.hasMore = true;
            this.fetchedOnce = false;
            // Optionally, you can fetch default entries when URL/params change.
            // For example, if you want to load defaults immediately, uncomment:
            if (this.value) {
                this.fetchItemDetail(this.value);
            }
            // this.fetchItems();
        },
        onMenuOpened() {
            // When the menu opens, if no items have been loaded, fetch default entries.
            if (!this.fetchedOnce) {
                this.fetchItems();
            }
        },
        fetchItems() {
            this.loading = true;
            this.offset = 0;
            axios
                .get(this.baseUrl, {
                    params: {
                        search: this.search,
                        offset: this.offset,
                        limit: this.limit,
                        ...this.additionalParams,
                    },
                })
                .then((response) => {
                    this.items = response.data.results;
                    this.hasMore = !!response.data.next;
                    this.fetchedOnce = true;
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        fetchMoreItems() {
            if (!this.hasMore) return;
            // Only fetch more if an initial fetch has occurred.
            if (!this.fetchedOnce) return;
            this.loading = true;
            this.offset += this.limit;
            axios
                .get(this.baseUrl, {
                    params: {
                        search: this.search,
                        offset: this.offset,
                        limit: this.limit,
                        ...this.additionalParams,
                    },
                })
                .then((response) => {
                    this.items = this.items.concat(response.data.results);
                    this.hasMore = !!response.data.next;
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        loadNextPage() {
            // This method is called when the infinite-autocomplete emits load-more.
            // It calls fetchMoreItems only if an initial fetch has been made.
            if (this.fetchedOnce) {
                this.fetchMoreItems();
            } else {
                this.fetchItems();
            }
        },
        fetchItemDetail(itemId) {
            axios
                .get(`${this.baseUrl}${itemId}/`)
                .then((response) => {
                    if (!this.items.find((item) => item.id === itemId)) {
                        this.items.push(response.data);
                    }
                })
                .catch((error) => {
                    console.error("Error fetching detail:", error);
                });
        },
        onSearchChanged(newSearch) {
            this.search = newSearch;
            if (this.search !== "") {
                this.offset = 0;
                // this.fetchItems();
            } else {
                // If search is cleared and no item is selected, clear items.
                // if (!this.selected) {
                this.items = [];
                this.hasMore = true;
                // }
            }
            this.fetchItems();
        },
        focus() {
            this.$refs.search.focus();
        },
    },
    mounted() {
        // Do not auto-fetch on mount; wait for the menu to open.
        if (this.value) {
            this.fetchItemDetail(this.value);
        }
    },
    i18n: {
        messages: {
            en: {
                select_item: "Select an item",
            },
            fr: {
                select_item: "Sélectionnez un élément",
            },
        },
    },
};
</script>

<style scoped>
/* Add any additional styling as needed */
</style>
