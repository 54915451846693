import catalogentries from './submodules/catalogentries';

const state = {

};

const mutations = {

};

const actions = {
    async init({ commit, dispatch, state }, params) {
        await dispatch('catalogentries/init', { filters: { limit: 10, ordering: "name" }, url: params.url });
        dispatch('catalogentries/fetchCatalogEntries');
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    modules: {
        catalogentries: catalogentries(),
    }
};
