var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.customer ? _c('div', {
    staticClass: "d-flex align-center customername"
  }, [_c('v-tooltip', {
    attrs: {
      "top": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on;
        return [_c('div', _vm._g({}, _vm.customer.entity ? on : null), [!_vm.link ? _c('span', [_vm._v(_vm._s(_vm.customer.name))]) : _vm.lookup ? _c('lookup', {
          attrs: {
            "object": "customer",
            "params": _vm.lookup
          }
        }, [_vm._v(" " + _vm._s(_vm.customer.name) + " ")]) : _vm.routeExists('customer') ? _c('router-link', {
          attrs: {
            "to": {
              name: 'customer',
              params: {
                customer_id: _vm.customer.id
              }
            }
          }
        }, [_c('Stroke', {
          attrs: {
            "strike": _vm.customer.archived
          }
        }, [_vm._v(" " + _vm._s(_vm.customer.name) + " ")])], 1) : _c('span', [_vm._v(_vm._s(_vm.customer.name))]), _vm.customer.entity ? _c('v-icon', {
          staticClass: "ml-1 icon",
          attrs: {
            "color": _vm.link ? 'primary' : null,
            "size": "20"
          }
        }, [_vm._v("mdi-link")]) : _vm._e()], 1)];
      }
    }], null, false, 3972536152)
  }, [_vm.customer.entity ? [_vm._v(" " + _vm._s(_vm.$translate("customerIsConnected")) + " ")] : _vm._e()], 2)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }