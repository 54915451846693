import axios from '@/plugins/axios';
import utils from '@/stores/utils';
import store from '@/stores/store';

const createState = () => ({
    products: [],
    // The initial endpoint URL for products.
    products_url: '/api/products/',
    // Separate field for storing the next page URL.
    next_products_url: null,
    products_filters: { offset: 0, limit: 40 },
    products_count: 0,
    products_loaded: false,
    products_loading: false,
    products_loading_errors: null,
    cancel_source: null,
});

const mutations = {
    updateProducts(state, products) {
        state.products = products;
        state.products_loaded = true;
        state.products_loading = false;
        state.products_loading_errors = null;
    },
    appendProducts(state, products) {
        state.products = state.products.concat(products);
    },
    updateProductsCount(state, products_count) {
        state.products_count = products_count;
    },
    updateProductsUrl(state, products_url) {
        state.products_url = products_url;
    },
    updateNextProductsUrl(state, nextUrl) {
        state.next_products_url = nextUrl;
    },
    updateProductsFilters(state, products_filters) {
        state.products_filters = products_filters;
    },
    updateProductsLoading(state, products_loading) {
        state.products_loading = products_loading;
    },
    updateProductsLoadingErrors(state, products_loading_errors) {
        state.products_loading_errors = products_loading_errors;
    },
    setCancelSource(state, cancel_source) {
        state.cancel_source = cancel_source;
    },
};

const actions = {
    async fetchProducts({ commit, state }) {
        if (
            store.getters['session/current_user_permissions'].indexOf("core.view_product") === -1
        ) {
            return;
        }

        if (state.cancel_source) {
            state.cancel_source.cancel('Operation canceled due to new request.');
        }

        const cancel_source = axios.CancelToken.source();
        commit('setCancelSource', cancel_source);

        commit('updateProductsLoading', true);
        commit('updateProductsLoadingErrors', null);

        try {
            // Call the initial endpoint with filters
            const response = await axios.get(state.products_url, {
                params: state.products_filters,
                cancelToken: cancel_source.token,
            });
            commit('updateProducts', response.data.results);
            commit('updateProductsCount', response.data.count);
            // Save the next URL separately
            commit('updateNextProductsUrl', response.data.next);
        } catch (xhr_error) {
            if (!axios.isCancel(xhr_error)) {
                const error = utils.handleError(xhr_error);
                commit('updateProductsLoadingErrors', error.details);
            }
        } finally {
            commit('updateProductsLoading', false);
            commit('setCancelSource', null);
        }
    },

    async fetchMoreProducts({ commit, state }) {
        // If there is no next URL, then we've reached the end.
        if (!state.next_products_url) {
            return;
        }

        if (state.cancel_source) {
            state.cancel_source.cancel('Operation canceled due to new request.');
        }

        const cancel_source = axios.CancelToken.source();
        commit('setCancelSource', cancel_source);

        commit('updateProductsLoading', true);
        commit('updateProductsLoadingErrors', null);

        try {
            // Use the next_products_url from state (it already contains GET parameters)
            const response = await axios.get(state.next_products_url, {
                cancelToken: cancel_source.token,
            });
            commit('appendProducts', response.data.results);
            // Update the next_products_url (could be null if no further pages)
            commit('updateNextProductsUrl', response.data.next);
        } catch (xhr_error) {
            if (!axios.isCancel(xhr_error)) {
                const error = utils.handleError(xhr_error);
                commit('updateProductsLoadingErrors', error.details);
            }
        } finally {
            commit('updateProductsLoading', false);
            commit('setCancelSource', null);
        }
    },

    init({ commit }, params) {
        commit('updateProducts', []);
        // Reset filters and use default ordering.
        const default_filters = { offset: 0, limit: 20, ordering: 'upc' };
        commit('updateProductsFilters', params ? params.filters || default_filters : default_filters);
        if (params && params.url) {
            commit('updateProductsUrl', params.url);
        }
        // Reset the next URL.
        commit('updateNextProductsUrl', null);
    },
};

export default () => ({
    namespaced: true,
    state: createState(),
    mutations,
    actions,
});
