import axios from "@/plugins/axios";
import utils from "@/stores/utils";
import catalogs from "./submodules/catalogs";

const state = {
    entity: null,
    catalog: null,
    allowed_catalogs: null,
    prospect_inviting: false,
    message: null,
};

const mutations = {
    updateEntity(state, entity) {
        state.entity = entity;
    },
    updateUsemail(state, usemail) {
        state.usemail = usemail;
    },
    updateEmail(state, email) {
        state.email = email;
    },
    updateName(state, name) {
        state.name = name;
    },
    updateCatalog(state, catalog) {
        state.allowed_catalogs = [catalog];
    },
    updateAllowedCatalogs(state, allowed_catalogs) {
        state.allowed_catalogs = allowed_catalogs;
    },
    updateProspectInviting(state, saving) {
        state.prospect_inviting = saving;
    },
    updateMessage(state, message) {
        state.message = message;
    },

};

const actions = {

    async inviteProspect({ commit, dispatch, state }, params) {
        try {
            commit("updateProspectInviting", true);
            const response = await axios.post("/api/invitations/", {
                email_or_safetee_id: state.entity?.safetee_id,
                email: state.email,
                catalogs: state.allowed_catalogs,
                prospect: state.entity.id,
                message: state.message,
            });
            return response.data;
        } catch (xhr_error) {
            let errors = utils.handleError(xhr_error);
            throw errors
        } finally {
            commit("updateProspectInviting", false);
        }
    },

    init({ commit, dispatch, state }, params) {
        commit("updateAllowedCatalogs", []);
        commit("updateMessage", null);
        commit("updateEntity", params.entity);
        dispatch("catalogs/init", { limit: 1000, ordering: "name" });
        dispatch("catalogs/fetchCatalogs");
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    modules: {
        catalogs: catalogs(),
    }
};
