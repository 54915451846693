<template>
    <div class="address mb-3">
        <h6 v-if="title" class="text-overline font-weight-bold">
            {{ title }}
        </h6>
        <v-row class="mt-0">
            <v-col cols="12" sm="6">
                <v-text-field
                    v-model="address.contact_name"
                    :error-messages="errors.contact_name"
                    :label="$translate('contactname')"
                    :disabled="readonly"
                    @input="$emit('input', address || {})"
                    :dense="true"
                />
            </v-col>
            <v-col cols="12" sm="6">
                <v-text-field
                    v-model="address.email"
                    :error-messages="
                        errors.email ||
                        (highlightMandatory && !address.email
                            ? [$translate('required')]
                            : null)
                    "
                    :label="$translate('email')"
                    :disabled="readonly"
                    @input="$emit('input', address || {})"
                    :dense="true"
                />
            </v-col>
            <v-col cols="12" sm="6">
                <v-text-field
                    v-model="address.line1"
                    :error-messages="
                        errors.line1 ||
                        (highlightMandatory && !address.line1
                            ? [$translate('required')]
                            : null)
                    "
                    :label="$translate('address')"
                    :disabled="readonly"
                    @input="$emit('input', address || {})"
                    :dense="true"
                />
            </v-col>

            <v-col cols="12" sm="6">
                <v-text-field
                    v-model="address.postal_code"
                    :error-messages="
                        errors.postal_code ||
                        (highlightMandatory && !address.postal_code
                            ? [$translate('required')]
                            : null)
                    "
                    :label="$translate('postalCode')"
                    :disabled="readonly"
                    @input="$emit('input', address || {})"
                    :dense="true"
                />
            </v-col>
            <v-col cols="12" sm="6">
                <v-text-field
                    v-model="address.city"
                    :error-messages="
                        errors.city ||
                        (highlightMandatory && !address.city
                            ? [$translate('required')]
                            : null)
                    "
                    :label="$translate('city')"
                    :disabled="readonly"
                    @input="$emit('input', address || {})"
                    :dense="true"
                />
            </v-col>

            <v-col cols="12" sm="6">
                <v-text-field
                    v-model="address.country"
                    :error-messages="errors.country"
                    :label="$translate('country')"
                    :disabled="readonly"
                    @input="$emit('input', address || {})"
                    :dense="true"
                />
            </v-col>

            <v-col cols="12" sm="6">
                <v-text-field
                    v-model="address.mobile_phone_number"
                    :error-messages="errors.mobile_phone_number"
                    :label="$translate('mobileNumber')"
                    :disabled="readonly"
                    @input="$emit('input', address || {})"
                    :dense="true"
                />
            </v-col>
            <v-col cols="12" sm="6">
                <v-text-field
                    v-model="address.phone_number"
                    :error-messages="errors.phone_number"
                    :label="$translate('phoneNumber')"
                    :disabled="readonly"
                    @input="$emit('input', address || {})"
                    :dense="true"
                />
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            required: false,
        },
        value: {
            type: Object,
            required: true,
        },
        errors: {
            type: Object,
            required: true,
            default: () => ({}),
        },
        readonly: {
            type: Boolean,
            required: false,
            default: false,
        },
        highlightMandatory: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    watch: {
        value(value) {
            this.address = value;
        },
    },
    data() {
        return {
            address: { ...(this.value || {}) },
        };
    },
    i18n: {
        messages: {
            en: {
                contactname: "Contact name",
                address: "Address",
                postalCode: "Postal code",
                city: "City",
                country: "Country",
                mobileNumber: "Mobile number",
                phoneNumber: "Phone number",
                email: "Email",
                required: "Required",
            },
            fr: {
                contactname: "Nom du contact",
                address: "Adresse",
                postalCode: "Code postal",
                city: "Ville",
                country: "Pays",
                mobileNumber: "Numéro de portable",
                phoneNumber: "Numéro de téléphone",
                email: "Email",
                required: "Requis",
            },
        },
    },
};
</script>
