import store from '@/stores/store';
import axios from '@/plugins/axios';
import utils from '@/stores/utils';
import allutils from '@/utils'
import providers from "./submodules/providers";
import producttypes from "./submodules/producttypes";


const state = {
    product: null,
    myproduct: {},
    product_loading: false,
    product_loading_errors: null,
    product_deleting: false,

    product_saving: false,
    product_saving_errors: {},

    producttype: null,

    purchaseprices: [],
    purchaseprices_count: 0,
    purchaseprices_loading: false,
    purchaseprices_loading_errors: null,
    purchaseprices_saving: false,
    purchaseprices_saving_errors: null,

    add_to_products: false,
};


const mutations = {

    updateProduct(state, product) {
        state.product_saving = false;
        state.product_saving_errors = {};

        if (product && state.product && product.id && state.product.id) {
            state.product = product;
            return;
        }
        state.purchaseprices = [];
        state.purchaseprices_count = 0;
        state.purchaseprices_loading_errors = null;
        state.product = product;
        state.producttype = product.producttype;
    },

    updateProductType(state, producttype) {
        state.producttype = producttype;
        state.product.producttype = producttype;
    },

    updateProductLoading(state, loading) {
        state.product_loading = loading;
    },
    updateProductLoadingErrors(state, errors) {
        state.product_loading_errors = errors;
    },

    updateProductSavingErrors(state, errors) {
        state.product_saving = false;
        state.product_saving_errors = errors;
    },
    updateProductSaving(state, saving) {
        state.product_saving = saving;
    },

    updatePurchasePrices(state, purchaseprices) {
        state.purchaseprices = purchaseprices;
    },
    updatePurchasePricesCount(state, count) {
        state.purchaseprices_count = count;
    },
    updatePurchasePricesLoading(state, loading) {
        state.purchaseprices_loading = loading;
    },
    updatePurchasePricesLoadingErrors(state, errors) {
        state.purchaseprices_loading_errors = errors;
    },
    updatePurchasePricesSavingErrors(state, errors) {
        state.purchaseprices_saving = false;
        state.purchaseprices_saving_errors = errors;
    },
    updatePurchasePricesSaving(state, saving) {
        state.purchaseprices_saving = saving;
    },
    updateProductProviders(state, providers) {
        state.product.providers = providers;
        if (state.product.provider) {
            if (providers) {
                state.product.provider = providers[0].provider;
                state.product.price = providers[0].price;
            } else {
                state.product.provider = null;
                state.product.price = null;
            }
        }
    },

    updateAddToProducts(state, add_to_products) {
        state.add_to_products = add_to_products;
    },

    updateMyProduct(state, product) {
        state.myproduct = product;
    },
};

const actions = {


    async fetchProduct({ commit, dispatch, state }, params) {
        commit('updateProductLoading', true);

        let url = state.product.provider ?
            `/api/providerproducts/${state.product.id}/` :
            `/api/products/${state.product.id}/`;

        try {
            const response = await axios.get(url);
            commit('updateProduct', response.data);
            return response.data;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            commit('updateProductLoadingErrors', error.details);
            throw error;
        } finally {
            commit('updateProductLoading', false);
        }
    },

    async saveProduct({ commit, dispatch, state }, params) {
        let url = state.product.provider ? '/api/providerproducts/' : '/api/products/';
        let method = axios.post;

        const producttype = state.producttypes.producttypes.find((item) => item.id === state.product.producttype);
        state.product.metadata = allutils.cleanupMetadata(state.product.metadata, producttype);

        if (state.product?.id) {
            url += `${state.product.id}/`;
            method = axios.patch;
        }
        let product = {
            ...state.product,
        };
        if (state.add_to_products) {
            product.add_to_products = state.myproduct;
        }

        try {
            const response = await method(url, product);
            dispatch('session/fetchStats', null, { root: true });
            return response;
        } catch (xhr_error) {
            let error = utils.handleError(xhr_error);
            commit('updateProductSavingErrors', error.details);
            throw error;
        }
    },



    async init({ commit, dispatch, state }, params) {
        let product =
        {
            metadata: {},
            packaging: store.state.session.settings.default_packaging,
            ...JSON.parse(
                JSON.stringify(
                    params.product || { purchase_prices: [], metadata: {} }
                )
            )
        };
        if (product.provider) {
            product.providers = [{ provider: product.provider, price: null }];
        }
        commit('updateProduct', product);
        if (!state.add_to_products) {
            commit('updateMyProduct', {
                available_for_sale: true,
                stock_tracking: true,
            });
        } else if (state.myproduct) {
            commit('updateMyProduct', {
                ...state.myproduct,
                reference: null,
                reference_url: null,
            });
        }

        dispatch('providers/init', { filters: { limit: 1000, connected: false, ordering: 'name', exclude_profile: "operator" }, url: `/api/providers/`, });
        dispatch('providers/fetchProviders');

        dispatch('producttypes/init');
        dispatch('producttypes/fetchProductTypes');
    },

};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    modules: {
        providers: providers(),
        producttypes: producttypes(),
    }
};

