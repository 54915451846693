import axios from '@/plugins/axios';
import utils from '@/stores/utils';

const createState = () => ({
    catalog: {},
    catalogagentassignments: [],
    catalogagentassignments_count: 0,
    catalogagentassignments_filters: { offset: 0, limit: 20, ordering: 'agent_name' },
    catalogagentassignments_loaded: false,
    catalogagentassignments_loading: false,
    catalogagentassignments_loading_errors: null,
});


const mutations = {

    updateCatalog(state, catalog) {
        state.catalog = catalog;
        state.catalogagentassignments = [];
        state.catalogagentassignments_count = 0;
        state.catalogagentassignments_loaded = false;
        state.catalogagentassignments_loading = false;
        state.catalogagentassignments_filters = { offset: 0, limit: 20, catalog: catalog ? catalog.id : null, ordering: 'agent_name' };
    },
    updateCatalogAgentAssignments(state, catalogagentassignments) {
        state.catalogagentassignments = catalogagentassignments;
        state.catalogagentassignments_loaded = true;
        state.catalogagentassignments_loading = false;
    },
    updateCatalogAgentAssignmentsCount(state, count) {
        state.catalogagentassignments_count = count;
    },
    updateCatalogAgentAssignmentsLoading(state, loading) {
        state.catalogagentassignments_loading = loading;
    },
    updateCatalogAgentAssignmentsLoadingErrors(state, errors) {
        state.catalogagentassignments_loading_errors = errors;
    },
    updateCatalogAgentAssignmentDeleting(state, deleting) {
        state.catalog_deleting_assignment = deleting;
    },
    updateCatalogAgentAssignmentDeletingErrors(state, errors) {
        state.catalog_deleting_assignments_errors = errors;
    },
    updateCatalogAgentAssignmentsFilters(state, filters) {
        state.catalogagentassignments_filters = filters;
    },
};


const getters = {

};

const actions = {

    async createCatalogAgentAssignment({ commit, dispatch, state }, data) {
        try {
            const response = await axios.post("/api/catalogagentassignments/", data);
            await dispatch('fetchCatalogAgentAssignments');
            return response.data;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            throw error;
        }
    },

    async fetchCatalogAgentAssignments({ commit, dispatch, state }, params) {
        commit('updateCatalogAgentAssignmentsLoading', true);
        commit('updateCatalogAgentAssignmentsLoadingErrors', null);

        try {
            const response = await axios.get("/api/catalogagentassignments/", { params: state.catalogagentassignments_filters });
            commit('updateCatalogAgentAssignments', response.data.results);
            commit('updateCatalogAgentAssignmentsCount', response.data.count);
            return response.data;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            commit('updateCatalogAgentAssignmentsLoadingErrors', error.details);
            throw error;
        } finally {
            commit('updateCatalogAgentAssignmentsLoading', false);
        }
    },

    async deleteCatalogAgentAssignment({ commit, dispatch, state }, params) {
        commit('updateCatalogAgentAssignmentDeleting', true);
        commit('updateCatalogAgentAssignmentDeletingErrors', null);

        try {
            const url = `/api/catalogagentassignments/${params.instance.id}/`;
            const response = await axios.delete(url);
            commit('updateCatalogAgentAssignmentDeleting', false);
            await dispatch('fetchCatalogAgentAssignments');
            return response.data;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            commit('updateCatalogAgentAssignmentDeleting', false);
            commit('updateCatalogAgentAssignmentDeletingErrors', error.details);
            throw error;
        }
    },

    async setDefaultCatalogAgentAssignment({ commit, dispatch, state }, params) {
        try {
            const url = `/api/catalogagentassignments/${params.instance.id}/default/`;
            let method = params.default ? axios.post : axios.delete;
            const response = await method(url);
            return response.data;
        } catch (xhr_error) {
            throw utils.handleError(xhr_error);
        }
    },




    init({ commit, dispatch, state }, params) {
        commit('updateCatalog', params.catalog);
        commit('updateCatalogAgentAssignmentsFilters', Object.assign({}, state.catalogagentassignments_filters, params.filters || {}));
    }
};

export default () => ({
    namespaced: true,
    state: createState(),
    mutations,
    actions
});