<template>
    <div
        class="filter text-body-2"
        :class="{ search: filter.type == 'search' }"
        :key="filterName"
    >
        <template v-if="filter.type == 'search' || !filter.type">
            <v-text-field
                :autofocus="filter.focus"
                :background-color="value ? 'primary-lighter3' : 'secondary'"
                :class="{ active: !!value }"
                :disabled="filter.disabled"
                :label="filter.label"
                :placeholder="placeHolder"
                :prepend-inner-icon="iconName('search')"
                :value="value"
                @input="$emit('change', $event || null)"
                clearable
                dense
                hide-details
                outlined
                single-line
            />
        </template>
        <template v-else-if="['float', 'integer'].indexOf(filter.type) != -1">
            <v-combobox
                :autofocus="filter.focus"
                :background-color="!!value ? 'primary-lighter3' : 'secondary'"
                :class="{ active: !!value }"
                :clearable="!filter.defaultLabel && filter.clearable != false"
                :disabled="filter.disabled"
                :items="choices | flattenChoices"
                :label="filterLabel"
                :loading="loading"
                :menu-props="{ closeOnClick: true }"
                :prepend-inner-icon="filterIcon(filter.icon)"
                :required="filter.required"
                :value="value"
                @input="$emit('change', $event || null)"
                type="number"
                dense
                flat
                hide-details
                outlined
            />
        </template>
        <template v-else-if="filter.type == 'input' || filter.type == 'string'">
            <v-combobox
                :autofocus="filter.focus"
                :background-color="!!value ? 'primary-lighter3' : 'secondary'"
                :class="{ active: !!value }"
                :clearable="!filter.defaultLabel && filter.clearable != false"
                :disabled="filter.disabled"
                :items="choices | flattenChoices"
                :label="filter.label"
                :loading="loading"
                :menu-props="{ closeOnClick: true }"
                :prepend-inner-icon="filterIcon(filter.icon)"
                :required="filter.required"
                :value="value"
                @input="$emit('change', $event)"
                class="autocomplete"
                dense
                flat
                hide-details
                outlined
            />
        </template>
        <template v-else-if="filter.type == 'boolean'">
            <v-checkbox
                :background-color="value ? 'primary-lighter3' : 'secondary'"
                :class="{ active: !!value }"
                :disabled="filter.disabled"
                :input-value="!!value || false"
                :key="value"
                :label="filter.label"
                :name="filterName"
                :prepend-inner-icon="filterIcon(filter.icon)"
                @change="booleanInputChanged"
                class="text-body-2 mt-0 checkbox"
                dense
                hide-details
                outlined
                single-line
            >
            </v-checkbox>
        </template>
        <template v-else-if="filter.type == 'select'">
            <v-autocomplete
                :autofocus="filter.focus"
                :background-color="value ? 'primary-lighter3' : 'secondary'"
                :class="{ active: !!value }"
                :clearable="!filter.defaultLabel && filter.clearable != false"
                :disabled="filter.disabled"
                :items="choices"
                :label="filter.label"
                :loading="loading"
                :menu-props="{ closeOnClick: true }"
                :prepend-inner-icon="filterIcon(filter.icon)"
                :required="filter.required"
                :value="value"
                @input="$emit('change', $event)"
                class="autocomplete"
                :no-data-text="$gettext('No data available')"
                dense
                flat
                hide-details
                outlined
        /></template>
        <template v-else-if="filter.type == 'combo'">
            <v-select
                :autofocus="filter.focus"
                :background-color="value ? 'primary-lighter3' : 'secondary'"
                :class="{ active: !!value }"
                :clearable="!filter.defaultLabel && filter.clearable != false"
                :disabled="filter.disabled"
                :items="choices"
                :label="filter.label"
                :loading="loading"
                :menu-props="{ closeOnClick: true }"
                :prepend-inner-icon="filterIcon(filter.icon)"
                :required="filter.required"
                :value="value"
                @input="$emit('change', $event)"
                class="combobox"
                dense
                flat
                hide-details
                outlined
        /></template>
        <template v-else-if="filter.type == 'searchselect'">
            <v-autocomplete
                :autofocus="filter.focus"
                :background-color="value ? 'primary-lighter3' : 'secondary'"
                :class="{ active: !!value }"
                :clearable="!filter.defaultLabel && filter.clearable != false"
                :disabled="filter.disabled"
                :items="choices"
                :label="filter.label"
                :loading="loading"
                :menu-props="{ closeOnClick: true }"
                :prepend-inner-icon="filterIcon(filter.icon)"
                :required="filter.required"
                :search-input="search"
                :value="value"
                @input="$emit('change', $event)"
                @update:search-input="updateSearch"
                class="autocomplete"
                dense
                flat
                hide-details
                outlined
        /></template>
        <template v-else-if="filter.type == 'datetime'">
            <DateTime
                :autofocus="filter.focus"
                :background-color="value ? 'primary-lighter3' : 'secondary'"
                :class="{ active: !!value }"
                :clearable="filter.clearable != false"
                :dense="true"
                :disabled="filter.disabled"
                :fixed="false"
                :flat="true"
                :label="filter.label"
                :loading="loading"
                :min-date="null"
                :outlined="true"
                :prepend-inner-icon="filterIcon(filter.icon)"
                :required="filter.required"
                :value="value"
                @input="$emit('change', $event)"
                class="autocomplete"
        /></template>
    </div>
</template>

<script>
import axios from "axios";
import GlobalMixins from "@/mixins/global";

export default {
    mixins: [GlobalMixins],
    props: {
        value: {
            required: false,
            default: null,
        },
        filter: {
            type: Object,
            required: false,
            default: () => {},
        },
    },
    data() {
        return {
            loading: false,
            source_choices: null,
            search: null,
            value_label: null,
        };
    },

    filters: {
        flattenChoices(choices) {
            if (!choices) {
                return [];
            }
            return choices.map((item) => item.value);
        },
    },
    watch: {
        value: {
            handler(value) {
                this.value_label = this.choices?.find(
                    (item) => item.value == this.value
                )?.text;
            },
        },
    },
    computed: {
        filterLabel() {
            let filterLabel = this.filter.label;
            if (this.filter.unit) {
                return `${filterLabel} (${this.filter.unit})`;
            }
            return filterLabel;
        },
        placeHolder() {
            return this.filter.placeholder || this.filter.label;
        },
        filterName() {
            return this.filter.field || this.filter.source;
        },
        choices() {
            let choices = [];
            if (this.filter.choices) {
                let choices = [];
                if (typeof this.filter.choices === "function") {
                    if (!this.source_choices) {
                        return [];
                    }
                    choices = this.filter.choices(this.source_choices);
                } else {
                    choices = this.filter.choices;
                }
                if (this.filter.defaultLabel) {
                    choices.insert(0, {
                        value: null,
                        text: this.filter.defaultLabel,
                    });
                }
                if (this.filter.sort) {
                    choices.sort((a, b) => {
                        return a.text.localeCompare(b.text);
                    });
                }
                return choices;
            }
            if (this.value && this.value_label && this.filter.type == "searchselect") {
                if (!this.source_choices) {
                    return [{ text: this.value_label, value: this.value }];
                }
            }

            return this.source_choices;
        },
    },
    methods: {
        updateSearch(search) {
            if (this.source_choices) {
                if (
                    this.source_choices.find((item) => {
                        item.text == search;
                    }) != -1
                ) {
                    this.search = null;
                    return;
                }
            }
            this.search = search;
            this.fetchSource();
        },
        filterIcon(icon) {
            return this.iconName(icon || "search");
        },
        booleanInputChanged(value) {
            if (value) {
                this.$emit("change", this.filter.value || true);
            } else {
                if (this.filter.force_value) {
                    this.$emit("change", false);
                } else {
                    this.$emit("change", null);
                }
            }
        },
        makeChoices(data, filter) {
            let choices = [];
            if (this.filter.defaultLabel) {
                choices.push({
                    value: null,
                    text: this.filter.defaultLabel,
                });
            }
            if (!this.filter.source) {
                data.results.forEach((item) => {
                    choices.push({
                        text: this.$utils.traverse(item, this.filter.text_field || "id"),
                        value: this.$utils.traverse(item, this.filter.value_field),
                    });
                });
                return choices;
            }
            switch (this.filter.source) {
                case "user":
                    data.results.forEach((item) => {
                        choices.push({
                            text: item.full_name,
                            value: item.id,
                        });
                    });
                    break;
                case "catalog":
                    data.results.forEach((item) => {
                        if (item.provider_data) {
                            choices.push({
                                text: item.provider_data.name,
                                value: item.id,
                            });
                        } else {
                            choices.push({
                                text:
                                    item.entity != this.current_entity_id
                                        ? `${item.owner_data?.name} - ${item.name}`
                                        : item.name || this.$gettext("Unnamed"),
                                value: item.id,
                            });
                        }
                    });
                    break;
                case "vendor":
                    data.results.forEach((item) => {
                        choices.push({
                            text: item.entity_data?.name,
                            value: item.entity,
                        });
                    });
                    break;

                case "provider":
                case "product":
                case "entity":
                case "customer":
                case "buyer":
                case "initiator":
                case "recipient":
                case "deliverysite":
                case "billingaccount":
                    data.results.forEach((item) => {
                        choices.push({ text: item.name, value: item.id });
                    });
                    break;
                case "stock":
                    data.results.forEach((item) => {
                        if (item.owned) {
                            choices.push({
                                value: item.id,
                                text: item.name,
                            });
                        } else {
                            choices.push({
                                value: item.id,
                                text: `${item.owner_data.name} - ${item.name}`,
                            });
                        }
                    });
                    break;

                case "productmetadata":
                    data.values.forEach((item) => {
                        choices.push({
                            text:
                                (item.text || item.value) +
                                (filter.unit ? ` ${filter.unit}` : ""),
                            value: item.value,
                        });
                    });
                    break;
                case "catalogentry_category":
                    data.forEach((item) => {
                        choices.push({
                            text: item,
                            value: item,
                        });
                    });
                    break;
            }
            return choices;
        },
        fetchSource() {
            const utils = require("@/stores/utils");
            let params = {};
            if (this.search && this.value) {
                return;
            }
            if (this.filter.type == "searchselect") {
                params = {
                    ...this.filter.params,
                    ...{ search: this.search, limit: 500 },
                };
            } else {
                params = { ...this.filter.params, ...{ limit: 10000 } };
            }
            this.loading = true;
            axios
                .get(this.filter.endpoint, { params })
                .then((response) => {
                    this.source_choices = this.makeChoices(response.data, this.filter);
                })
                .catch((xhr_error) => {
                    console.error(xhr_error);
                    this.source_choices = [];
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    },
    mounted() {
        if (this.filter.endpoint) {
            this.fetchSource();
        }
    },
};
</script>

<style lang="scss">
@import "@/assets/css/variables.scss";

.filter {
    i:before,
    i:after {
        color: $color-primary;
    }
    :not(input[type="text"]:autofocus) {
        cursor: pointer;
    }

    label,
    input[type="text"] {
        font-size: 12px;
        color: $color-gs-black !important;
    }

    fieldset {
        border: none !important;
    }
    .autocomplete {
        label,
        input[type="text"] {
            font-size: 12px;
        }
    }
    .v-input {
        padding-top: 0 !important;
    }
    .v-input__slot {
        padding: 8px;
        border-radius: $text-field-border-radius;
    }
    .v-input--selection-controls {
        padding-top: 0;
    }
}
</style>
