import Vue from 'vue';
import axios from '@/plugins/axios';
import storeUtils from '@/stores/utils';
import Decimal from 'decimal.js';
import utils from '@/utils';

const state = {
    billingaccount: null,
    billingaccount_loading: false,
    invoice: null,
    invoice_file_url: null,
    settings: {},
    validating_invoice_errors: {},
    validating_invoice: false,
    parsing_invoice: false,
    parsing_invoice_errors: null,
    parsing_mismatch: false,
    invoice_data: null,
};

const getters = {
};

const mutations = {

    updateInvoice(state, invoice) {
        state.invoice = invoice;
        state.invoice_file_url = invoice?.invoice_file;
        state.validating_invoice = false;
        state.validating_invoice_errors = {};
    },

    updateValidatingInvoice(state) {
        state.validating_invoice = true;
        state.validating_invoice_errors = {};
    },

    updateValidatingInvoiceErrors(state, validating_invoice_errors) {
        state.validating_invoice_errors = validating_invoice_errors;
        state.validating_invoice = false;
    },

    updateBillingAccount(state, billingaccount) {
        state.billingaccount = billingaccount;
    },
    updateBillingAccountLoading(state, loading) {
        state.billingaccount_loading = loading;
    },

    updateInvoiceFile(state, invoice_file) {
        if (invoice_file) {
            state.invoice.invoice_file = invoice_file;
            state.invoice_file_url = URL.createObjectURL(invoice_file);
        } else {
            if (state.invoice_file_url) {
                state.remove_invoice_file = true;
            }
            state.invoice.invoice_file = null;
            state.invoice_file_url = null;
        }
    },

    updateParsingInvoice(state, parsing_invoice) {
        state.parsing_invoice = parsing_invoice;
    },

    updateParsingInvoiceErrors(state, parsing_invoice_errors) {
        state.parsing_invoice_errors = parsing_invoice_errors;
    },

    updateInvoiceData(state, invoice_data) {
        state.invoice[invoice_data.key] = invoice_data.value;
        state.invoice['total_incl_vat'] = (new Decimal(state.invoice['total_excl_vat']).plus(new Decimal(state.invoice['total_vat']))).toNumber();
    },

    forceupdateInvoiceWithParsedData(state) {
        let new_invoice = { ...state.invoice };
        if (state.invoice_data.invoice_id) {
            new_invoice.invoice_id = state.invoice_data.invoice_id;
        }
        if (state.invoice_data.invoice_date) {
            new_invoice.invoice_date = state.invoice_data.invoice_date;
        }
        if (state.invoice_data.due_date) {
            new_invoice.due_date = state.invoice_data.due_date;
        }
        state.invoice = new_invoice;
    },

    async updateInvoiceWithParsedData(state, invoice_data) {
        state.invoice_data = invoice_data;
        state.parsing_mismatch = false;
        const keys = ['invoice_id', 'invoice_date', 'due_date', 'total_excl_vat', 'total_vat', 'total_incl_vat'];

        for (let key of keys) {
            if (invoice_data[key] && state.invoice[key]) {
                if (invoice_data[key] != state.invoice[key]) {
                    state.parsing_mismatch = true;
                }
            }
        }
        for (let key of ['invoice_id', 'invoice_date', 'due_date']) {
            if (invoice_data[key] && !state.invoice[key]) {
                state.invoice[key] = invoice_data[key];
            }
        }
    },
};


const actions = {

    validateInvoice({ commit, dispatch, state, rootGetters }, params) {
        if (rootGetters['session/current_user_permissions'].indexOf("billing.change_invoice") === -1) {
            return;
        }
        let data = new FormData();
        let options = {};

        if (!state.billingaccount.handle_invoices) {
            data.append('invoice_file', state.invoice.invoice_file);
            data.append('invoice_date', state.invoice.invoice_date);
            data.append('invoice_due_date', state.invoice.due_date);
        }
        options.headers = { 'Content-Type': 'multipart/form-data' };
        data.append('invoice_id', state.invoice.invoice_id);

        return new Promise((resolve, reject,) => {
            commit('updateValidatingInvoice');
            axios.post(`/api/customerinvoices/${state.invoice.id}/validate/`, data, options)
                .then((response) => {
                    resolve(response.data);
                    dispatch('session/fetchStats', null, { root: true });
                    dispatch('billingstats/fetchAllStats', null, { root: true });
                })
                .catch((xhr_error) => {
                    const error = storeUtils.handleError(xhr_error);
                    commit('updateValidatingInvoiceErrors', error.details);
                    reject(error);
                })
        });
    },


    fetchBillingAccount({ commit, dispatch, state, rootGetters }, params) {
        if (rootGetters['session/current_user_permissions'].indexOf("billing.view_billingaccount") === -1) {
            return;
        }
        commit('updateBillingAccountLoading', true);
        return new Promise((resolve, reject,) => {
            axios.get(`/api/billingaccounts/${state.invoice.billingaccount}/`)
                .then((response) => {
                    commit('updateBillingAccount', response.data);
                    resolve(response.data);
                })
                .catch((xhr_error) => {
                    const error = storeUtils.handleError(xhr_error);
                    reject(error);
                })
                .finally(() => {
                    commit('updateBillingAccountLoading', false);
                });
        });
    },



    async parseInvoiceFile({ commit, dispatch, state }, params) {
        commit("updateInvoiceFile", params.invoice_file);
        commit("updateParsingInvoice", true);
        let url = "/api/billing/parseinvoice/";
        try {
            let data = new FormData();
            data.append("invoice_file", params.invoice_file);
            let response = await axios.post(url, data);
            commit("updateParsingInvoice", false);
            await commit("updateInvoiceWithParsedData", response.data);
            return state.parsing_mismatch;

        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            commit("updateParsingInvoiceErrors", error.details);
            commit("updateParsingInvoice", false);
            throw error;
        }
    },

    init({ commit, dispatch, state }, params) {
        commit('updateBillingAccount', null);
        commit('updateInvoice', params?.invoice);
        commit('updateInvoiceFile', null);

        if (params.invoice) {
            dispatch('fetchBillingAccount');
        }

    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
