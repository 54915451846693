import axios from '@/plugins/axios';
import qs from 'qs';
import utils from '@/stores/utils';
import catalogs from './submodules/catalogs';
import catalogagentassignments from './submodules/catalogagentassignments';

const state = {
    agent: null
};

const mutations = {
    updateAgent(state, agent) {
        state.agent = agent;
    },
};

const actions = {

    async init({ commit, dispatch, state }, params) {
        commit('updateAgent', params.agent);
        dispatch('catalogs/init', {});
        dispatch('catalogs/fetchCatalogs');
        dispatch('catalogagentassignments/init', { filters: { agent: state.agent.id, ordering: 'catalog_name' } });
        dispatch('catalogagentassignments/fetchCatalogAgentAssignments');
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    modules: {
        catalogagentassignments: catalogagentassignments(),
        catalogs: catalogs(),
    }
};
