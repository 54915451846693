import axios from "@/plugins/axios";
import utils from "@/stores/utils";
import invitations from "./submodules/invitations";
import catalogs from "./submodules/catalogs";

const state = {
    search: null,
    searching: false,
    searching_errors: false,
    results: [],
    entity: null,
    customer: null,
    usemail: false,
    email: null,
    name: null,
    catalog: null,
    allowed_catalogs: null,
    customer_inviting: false,
    message: null,
};

const mutations = {
    updateSearch(state, search) {
        state.search = search;
    },
    updateSearching(state, saving) {
        state.searching = saving;
    },
    updateSearchingErrors(state, errors) {
        state.searching = false;
        state.searching_errors = errors;
    },
    updateResults(state, results) {
        state.results = results;
    },
    updateEntity(state, entity) {
        state.entity = entity;
        if (entity) {
            state.name = entity.name;
        }
    },
    updateCustomer(state, customer) {
        state.customer = customer;
    },
    updateUsemail(state, usemail) {
        state.usemail = usemail;
    },
    updateEmail(state, email) {
        state.email = email;
    },
    updateName(state, name) {
        state.name = name;
    },
    updateCatalog(state, catalog) {
        state.allowed_catalogs = [catalog];
    },
    updateAllowedCatalogs(state, allowed_catalogs) {
        state.allowed_catalogs = allowed_catalogs;
    },
    updateCustomerInviting(state, saving) {
        state.customer_inviting = saving;
    },
    updateMessage(state, message) {
        state.message = message;
    },

};

const actions = {

    async searchCustomer({ commit, dispatch, state }, params) {
        if (!state.search) {
            commit("updateResults", []);
            return;
        }
        commit("updateSearching", true);

        try {
            let response = await axios.get("/api/searchentities/", { params: { search: state.search, exclude_customers: true } })
            commit("updateResults", response.data);
            commit("updateSearching", false);
            return response;
        } catch (xhr_error) {
            let errors = utils.handleError(xhr_error);
            commit("updateSearchingErrors", errors.details);
            throw errors
        }
    },


    async inviteCustomer({ commit, dispatch, state }, params) {
        try {
            commit("updateCustomerInviting", true);
            if (state.customer?.id) {
                const response = await axios.patch(`/api/customers/${state.customer.id}/`, {
                    email_or_safetee_id: state.entity?.safetee_id || state.email,
                    catalogs: state.allowed_catalogs,
                });
                return response.data;
            }
            const response = await axios.post("/api/customers/", {
                name: state.customer?.name || state.name,
                email_or_safetee_id: state.entity?.safetee_id || state.email,
                catalogs: state.allowed_catalogs,
            });
            return response.data;
        } catch (xhr_error) {
            let errors = utils.handleError(xhr_error);
            throw errors
        } finally {
            commit("updateCustomerInviting", false);
        }
    },



    init({ commit, dispatch, state }, params) {
        commit("updateSearch", params?.customer?.name);
        commit("updateEmail", params?.customer?.billing_address?.email);
        commit("updateName", null);
        commit("updateUsemail", false);
        commit("updateAllowedCatalogs", []);
        commit("updateResults", []);
        commit("updateEntity", params.entity);
        commit("updateCustomer", params.customer);
        dispatch('searchCustomer');
        dispatch("catalogs/init", { limit: 1000, ordering: "name" });
        dispatch("catalogs/fetchCatalogs");
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    modules: {
        invitations: invitations(),
        catalogs: catalogs(),
    }
};
