import axios from '@/plugins/axios';
import qs from 'qs';
import utils from '@/stores/utils';
import store from '@/stores/store';

const state = {
    section: null,
    shipments: [],
    shipments_filters: { limit: 20, ordering: '-delivery_date' },
    shipments_count: 0,
    shipments_loading: false,
    shipments_loading_errors: null,
    shipments_cancel_source: null,

    shipments_stats: null,
    shipments_stats_loading: false,
    shipments_stats_loading_errors: null,
    shipments_stats_cancel_source: null,

};

let getModeFilters = function (section) {

    // Logisiticien :
    // - En attente - waiting


    if (section == 'waiting') {
        return {
            ready: true,
            readonly: false,
            status: 'waiting',
            delegated: true,
        };
    } else if (section == 'preparing') {
        return {
            ready: true,
            readonly: false,
            status: 'preparing',
        };
    } else if (section == 'prepared') {
        return {
            ready: true,
            readonly: false,
            status: 'prepared',
        };
    } else if (section == 'processing') {
        return {
            ready: true,
            readonly: false,
            status__in: 'preparing,prepared',
        };
    } else if (section == 'shipping') {
        return {
            ready: true,
            readonly: false,
            exclude_readonly: true,
            status: 'shipping',
        };
    } else if (section == 'all') {
        return {
            exclude_readonly: false,
        }
    } else if (section == 'drafts') {
        return {
            ready: false,
            readonly: false,
            exclude_readonly: false,
        };
    } else if (section == 'selfhandling_waiting') {
        return {
            ready: true,
            readonly: false,
            delegated: false,
            status: 'waiting',
        };
    } else if (section == 'selfhandling_preparing') {
        return {
            ready: true,
            readonly: false,
            delegated: false,
            status: 'preparing',
        };
    } else if (section == 'selfhandling_prepared') {
        return {
            ready: true,
            readonly: false,
            delegated: false,
            status: 'prepared',
        };
    } else if (section == 'selfhandling_shipping') {
        return {
            ready: true,
            readonly: false,
            delegated: false,
            status: 'shipping',
        };
    } else if (section == 'delegated') {
        return {
            ready: true,
            readonly: false,
            delegated: true,
        };
    } else if (section == 'delegated_waiting') {
        return {
            ready: true,
            readonly: false,
            delegated: true,
            status: 'waiting',
        };
    } else if (section == 'delegated_processing') {
        return {
            ready: true,
            readonly: false,
            delegated: true,
            status__in: 'waiting,preparing,prepared',
        };
    } else if (section == 'delegated_shipping') {
        return {
            ready: true,
            readonly: false,
            delegated: true,
            status: 'shipping',
        };
    }

};

const mutations = {

    updateSection(state, mode) {
        state.shipments = [];
        state.shipments_count = 0;
        state.section = mode;

        state.shipments_filters = {
            offset: 0,
            limit: state.shipments_filters.limit || 20,
            search: state.shipments_filters.search,
            ordering: state.shipments_filters.ordering,
            owner: state.shipments_filters.owner,
            recipient: state.shipments_filters.recipient,
        };

        state.shipments_filters = Object.assign({}, state.shipments_filters, getModeFilters(state.section));
        if (store.getters['session/current_entity_profile'] == 'customer') {
            state.shipments_filters.initiated = true;
            state.shipments_filters.owner = store.getters['session/current_entity_id'];
        }
    },

    updateShipments(state, shipments) {
        state.shipments = shipments;
    },
    updateShipmentsCount(state, count) {
        state.shipments_count = count;
    },
    updateShipmentsLoading(state, loading) {
        state.shipments_loading = loading;
    },
    updateShipmentsLoadingErrors(state, errors) {
        state.shipments_loading_errors = errors;
    },
    updateShipmentsFilters(state, shipments_filters) {
        state.shipments_filters = Object.assign(
            {
                limit: state.shipments_filters.limit || 20,
                ordering: state.shipments_filters.ordering,
            },
            getModeFilters(state.section),
            shipments_filters);
    },

    updateShipmentsCancelSource(state, source) {
        state.shipments_cancel_source = source;
    },

    updateShipmentsStats(state, stats) {
        state.shipments_stats = stats;
    },

    updateShipmentsStatsLoading(state, loading) {
        state.shipments_stats_loading = loading;
    },

    updateShipmentsStatsLoadingErrors(state, errors) {
        state.shipments_stats_loading_errors = errors;
    },

    updateShipmentsStatsCancelSource(state, source) {
        state.shipments_stats_cancel_source = source;
    },



};

const actions = {
    changeSection({ commit, dispatch, state }, section) {
        if (section == state.section) {
            return;
        }
        commit('updateSection', section);
        dispatch('fetchShipments');
        dispatch('session/fetchStats', null, { root: true });
    },

    exportShipmentItemssAsCSV({ commit, dispatch, state }, params) {
        let filters = {};
        if (params.filtered) {
            filters.shipment_ready = state.shipments_filters.ready;
            filters.shipment_readonly = state.shipments_filters.shipment_readonly;
            filters.shipment_status = state.shipments_filters.status;
            filters.ordering = state.shipments_filters.ordering;
            filters.exclude_readonly_shipments = state.shipments_filters.exclude_readonly;
            filters.delivery_date_around = state.shipments_filters.delivery_date_around;
            filters.owner = state.shipments_filters.owner;
            filters.recipient = state.shipments_filters.recipient;
            filters.stock = state.shipments_filters.stock;
            filters.search = state.shipments_filters.search;
            filters.offset = null;
        }
        filters.format = 'csv';
        filters.limit = 5000;
        const queryparams = qs.stringify(filters, { arrayFormat: 'repeat' });
        window.open("/api/shipmentitems/?" + queryparams);
    },

    exportShipmentsAsCSV({ commit, dispatch, state }, params) {
        let filters = {};
        if (params.filtered) {
            filters = Object.assign({}, state.shipments_filters);
            filters.offset = null;
        }
        filters.format = 'csv';
        filters.limit = 10000;
        const queryparams = qs.stringify(filters, { arrayFormat: 'repeat' });
        window.open("/api/shipments/?" + queryparams);
    },

    fetchShipments({ commit, dispatch, state }, params) {
        if (store.getters['session/current_user_permissions'].indexOf("core.view_shipment") == -1) {
            return;
        }

        commit('updateShipmentsLoading', true);
        commit('updateShipmentsLoadingErrors', null);

        if (state.shipments_cancel_source) {
            state.shipments_cancel_source.cancel("canceled");
        }

        const cancelSource = axios.CancelToken.source();
        commit('updateShipmentsCancelSource', cancelSource);

        return new Promise((resolve, reject) => {

            axios.get('/api/shipments/', {
                params: state.shipments_filters,
                cancelToken: state.shipments_cancel_source?.token
            })
                .then((response) => {
                    commit('updateShipmentsLoading', false);
                    commit('updateShipments', response.data.results);
                    commit('updateShipmentsCount', response.data.count);
                    commit('updateShipmentsCancelSource', null);
                    resolve(response);
                })
                .catch((xhr_error) => {
                    if (axios.isCancel(xhr_error)) {
                        return;
                    }
                    const error = utils.handleError(xhr_error);
                    commit('updateShipmentsLoadingErrors', error.details);
                    commit('updateShipmentsLoading', false);
                    commit('updateShipmentsCancelSource', null);
                    reject(error);
                })
                .finally(() => {
                    dispatch('fetchShipmentsStats');
                })
        });
    },

    async fetchShipmentsStats({ commit, state }) {

        commit('updateShipmentsStatsLoading', true);

        if (state.shipments_stats_cancel_source) {
            state.shipments_stats_cancel_source.cancel("canceled");
        }

        const cancelSource = axios.CancelToken.source();
        commit('updateShipmentsStatsCancelSource', cancelSource);

        try {
            const response = await axios.get('/api/shipments/stats/', {
                params: state.shipments_filters,
                cancelToken: state.shipments_stats_cancel_source?.token
            });
            commit('updateShipmentsStats', response.data);
            commit('updateShipmentsStatsCancelSource', null);
            return response.data;
        } catch (xhr_error) {
            if (axios.isCancel(xhr_error)) {
                return;
            }
            const error = utils.handleError(xhr_error);
            commit('updateShipmentsStatsLoading', false);
            throw utils.handleError(xhr_error);
        } finally {
            commit('updateShipmentsStatsLoading', false);
        }
    },

    assignShipment({ commit, dispatch, state }, params) {
        const url = `/api/shipments/${params.shipment.shipment_id}/assign/`;

        return new Promise((resolve, reject) => {
            axios.put(url, { user: params.user })
                .then((response) => {
                    resolve(response);
                })
                .catch((xhr_error) => {
                    reject(utils.handleError(xhr_error));
                }).finally(() => {
                    dispatch('fetchShipments');
                    dispatch('session/fetchStats', null, { root: true });
                });
        });
    },


    async setShipmentStatus({ commit, dispatch, state }, params) {

        try {
            const url = `/api/shipments/${params.shipment}/status/`;
            const response = await axios.put(url, { status: params.status })
            dispatch('session/fetchStats', null, { root: true });
            return response;
        } catch (xhr_error) {
            throw utils.handleError(xhr_error);
        }
    },

};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
