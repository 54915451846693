var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-menu', {
    attrs: {
      "offset-y": "",
      "nudge-top": _vm.nudgeTop,
      "transition": "scale-transition",
      "attach": _vm.attach,
      "content-class": _vm.contentClass,
      "disabled": _vm.disabled
    },
    on: {
      "input": _vm.onMenuChange
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('v-text-field', _vm._g(_vm._b({
          ref: "activator",
          attrs: {
            "value": _vm.displayText,
            "label": _vm.labelText + (_vm.required ? ' (*)' : ''),
            "clearable": !_vm.required,
            "loading": _vm.isLoading,
            "disabled": _vm.disabled || _vm.readonly,
            "autofocus": _vm.autofocus,
            "required": _vm.required,
            "append-icon": _vm.menu ? 'mdi-menu-up' : 'mdi-menu-down'
          },
          on: {
            "click": _vm.selectText,
            "click:clear": _vm.clearSelection,
            "input": _vm.onSearchChange
          }
        }, 'v-text-field', attrs, false), on))];
      }
    }]),
    model: {
      value: _vm.menu,
      callback: function callback($$v) {
        _vm.menu = $$v;
      },
      expression: "menu"
    }
  }, [_c('v-card', {
    ref: "card",
    staticClass: "pa-0",
    style: _vm.cardStyle,
    on: {
      "scroll": _vm.onScroll
    }
  }, [_c('v-list', [_vm.items.length === 0 ? [_c('v-list-item', [_c('v-list-item-title', {
    staticClass: "text-center"
  }, [_vm._v(" " + _vm._s(_vm.isLoading ? _vm.$translate("loading_in_progress") : _vm.$translate("no_results")) + " ")])], 1)] : [_vm._l(_vm.items, function (item) {
    return _c('v-list-item', {
      key: item[_vm.selectedKey],
      on: {
        "click": function click($event) {
          return _vm.selectItem(item);
        }
      }
    }, [_vm._t("item", function () {
      return [_c('v-list-item-title', [_vm._v(_vm._s(_vm.itemText(item)))])];
    }, {
      "item": item
    })], 2);
  }), _vm.loading ? _c('v-list-item', [_c('v-list-item-title', {
    staticClass: "text-center"
  }, [_c('v-progress-circular', {
    staticClass: "mr-1",
    attrs: {
      "indeterminate": "",
      "color": "primary",
      "width": "1",
      "size": 15
    }
  }), _vm._v(" " + _vm._s(_vm.$translate("loading_in_progress")) + " ")], 1)], 1) : _vm._e()]], 2)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }