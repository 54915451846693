<template>
    <div class="py-0 py-sm-1">
        <v-tooltip top>
            <template v-slot:activator="{ on }">
                <div v-on="tooltip ? on : null">
                    <stroke :strike="product.archived || strike" class="mr-1 mb-1">
                        <span class="mr-1 mb-0 mt-sm-0">
                            <div class="align-center">
                                <div v-if="upc && !inlineUpc" class="mb-1 mr-2">
                                    <router-link
                                        :to="{
                                            name: type,
                                            params: { product_id: product.id },
                                        }"
                                    >
                                        {{ product.upc }}
                                    </router-link>
                                </div>
                                <span class="font-weight-bold">
                                    <template v-if="upc && inlineUpc"
                                        >{{ product.upc }} -
                                    </template>
                                    <template v-if="product.name">{{
                                        product.name
                                    }}</template
                                    ><i class="warning--text" v-else v-translate
                                        >No name</i
                                    >
                                    <template v-if="product.manufacturer && manufacturer">
                                        <i class="grey--text text--darken-2 text-justify">
                                            {{ isMobile ? "" : "-" }}
                                            {{ product.manufacturer }}</i
                                        >
                                    </template>
                                </span>
                            </div>
                            <template v-if="product.description && description">
                                <div class="mt-0 wrap text-justify">
                                    {{ product.description }}
                                </div>
                            </template>
                        </span>
                    </stroke>
                </div>
            </template>
            <span>{{ tooltip }}</span>
        </v-tooltip>
    </div>
</template>

<script>
import GlobalMixins from "@/mixins/global";

export default {
    mixins: [GlobalMixins],
    props: {
        product: {
            required: true,
            type: Object,
        },
        upc: {
            type: Boolean,
            default: false,
        },
        inlineUpc: {
            type: Boolean,
            default: false,
        },
        manufacturer: {
            type: Boolean,
            default: true,
        },
        description: {
            type: Boolean,
            default: true,
        },
        type: {
            type: String,
            default: "product",
        },
        strike: {
            type: Boolean,
            default: false,
        },
        tooltip: {
            type: String,
            default: null,
        },
    },
};
</script>
