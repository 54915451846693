import axios from '@/plugins/axios';
import utils from '@/stores/utils';
import providers from "./submodules/providers";


const state = {
    productlink: null,
    provider: null,
    product: null,
    productlink_saving: false,
    action: null
};

const mutations = {
    updateProductLink(state, productlink) {
        state.productlink = productlink;
    },
    updateProvider(state, provider) {
        state.provider = provider;
    },
    updateProduct(state, product) {
        state.product = product;
    },
    updateProductLinkSaving(state, saving) {
        state.productlink_saving = saving;
    },
    updateAction(state, action) {
        state.action = action;
    },
};

const actions = {

    async changeProvider({ state, commit, dispatch }, provider) {
        commit('updateProvider', provider);
        commit('updateProduct', null);
    },

    async changeProduct({ state, commit, dispatch }, product) {
        commit('updateProduct', product);
    },

    async saveProductLink({ state, commit, dispatch }) {
        try {
            commit('updateProductLinkSaving', true);
            let payload = { child: state.productlink.child };
            if (state.action == 'linkproduct') {
                payload['parent'] = state.product;
            } else if (state.action == 'createproduct') {
                payload['provider'] = state.provider;
            } else {
                throw 'Invalid action';
            }
            const response = await axios.post(`/api/productlinks/`, payload);
            commit('updateProductLink', response.data);
            return response
        } catch (xhr_error) {
            let errors = utils.handleError(xhr_error);
            throw errors;
        } finally {
            commit('updateProductLinkSaving', false);
        }
    },

    async init({ commit, dispatch, state }, params) {
        commit('updateProductLink', params.productlink);
        commit('updateProvider', params.provider);
        commit('updateProduct', params?.productlink?.parent);

        dispatch('providers/init', { filters: { limit: 1000, ordering: 'name' } });
        dispatch('providers/fetchProviders');
    },

};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    modules: {
        providers: providers(),
    }
};

