import axios from '@/plugins/axios';
import qs from 'qs';
import utils from '@/stores/utils';

const state = {
    agents: [],
    agents_count: 0,
    agents_filters: { offset: 0, limit: 20, ordering: 'name', connected: true },
    agents_loading: false,
    agents_loading_errors: null,
    agents_cancel_source: null,

    synchronizing_agents: false,
    synchronizing_agents_errors: {}

};

const mutations = {
    updateAgents(state, agents) {
        state.agents = agents;
    },
    updateAgentsCount(state, count) {
        state.agents_count = count;
    },
    updateAgentsLoading(state, loading) {
        state.agents_loading = loading;
    },
    updateAgentsLoadingErrors(state, errors) {
        state.agents_loading_errors = errors;
    },
    updateAgentsFilters(state, filters) {
        state.agents_filters = filters;
    },
    updateAgentsCancelSource(state, source) {
        state.agents_cancel_source = source;
    },
    updateSynchronizingAgents(state, synchronizing) {
        state.synchronizing_agents = synchronizing;
    },
    updateSynchronizingAgentsErrors(state, errors) {
        state.synchronizing_agents_errors = errors;
    },

};

const actions = {

    async fetchAgents({ commit, dispatch, state }, params) {
        commit('updateAgentsLoading', true);
        commit('updateAgentsLoadingErrors', null);

        if (state.agents_cancel_source) {
            state.agents_cancel_source.cancel("canceled");
        }

        const cancelSource = axios.CancelToken.source();
        commit('updateAgentsCancelSource', cancelSource);


        try {
            const response = await axios.get('/api/agents/', {
                params: state.agents_filters,
                cancelToken: state.agents_cancel_source?.token,
            });
            commit('updateAgentsLoading', false);
            commit('updateAgents', response.data.results);
            commit('updateAgentsCount', response.data.count);
            commit('updateAgentsCancelSource', null);
            return response;
        } catch (xhr_error) {
            if (axios.isCancel(xhr_error)) {
                return;
            }
            const error = utils.handleError(xhr_error);
            commit('updateAgentsLoading', false);
            commit('updateAgentsLoadingErrors', error.details);
            throw error;
        }
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
