<template>
    <v-menu
        v-model="menu"
        offset-y
        :nudge-top="nudgeTop"
        transition="scale-transition"
        :attach="attach"
        @input="onMenuChange"
        :content-class="contentClass"
        :disabled="disabled"
    >
        <!-- Activator slot: the text field that triggers the menu -->
        <template v-slot:activator="{ on, attrs }">
            <v-text-field
                ref="activator"
                v-bind="attrs"
                v-on="on"
                :value="displayText"
                :label="labelText + (required ? ' (*)' : '')"
                :clearable="!required"
                :loading="isLoading"
                :disabled="disabled || readonly"
                @click="selectText"
                @click:clear="clearSelection"
                @input="onSearchChange"
                :autofocus="autofocus"
                :required="required"
                :append-icon="menu ? 'mdi-menu-up' : 'mdi-menu-down'"
            />
        </template>

        <!-- Menu content: a card with a scrollable list; infinite scroll via scroll event -->
        <v-card ref="card" class="pa-0" :style="cardStyle" @scroll="onScroll">
            <v-list>
                <!-- When no items are available -->
                <template v-if="items.length === 0">
                    <v-list-item>
                        <v-list-item-title class="text-center">
                            {{
                                isLoading
                                    ? $translate("loading_in_progress")
                                    : $translate("no_results")
                            }}
                        </v-list-item-title>
                    </v-list-item>
                </template>
                <!-- Otherwise, display the list items -->
                <template v-else>
                    <v-list-item
                        v-for="item in items"
                        :key="item[selectedKey]"
                        @click="selectItem(item)"
                    >
                        <slot name="item" :item="item">
                            <v-list-item-title>{{ itemText(item) }}</v-list-item-title>
                        </slot>
                    </v-list-item>
                    <!-- If loading is in progress, show a loading indicator at the bottom -->
                    <v-list-item v-if="loading">
                        <v-list-item-title class="text-center">
                            <v-progress-circular
                                indeterminate
                                color="primary"
                                width="1"
                                class="mr-1"
                                :size="15"
                            />
                            {{ $translate("loading_in_progress") }}
                        </v-list-item-title>
                    </v-list-item>
                </template>
            </v-list>
        </v-card>
    </v-menu>
</template>

<script>
export default {
    name: "InfiniteSelect",
    props: {
        // Array of items to display.
        items: {
            type: Array,
            default: () => [],
        },
        // Translation key for the text field label.
        label: {
            type: String,
            required: false,
        },
        // The current value (typically the identifier of the selected item).
        value: {
            type: [String, Number],
            default: null,
        },
        // Function to extract display text from an item.
        itemText: {
            type: Function,
        },
        // The key used to identify an item in the items array.
        selectedKey: {
            type: String,
            default: "id",
        },
        // Attach prop for the menu.
        attach: {
            type: [Boolean, String, Object],
            default: false,
        },
        // Custom class for the menu content.
        contentClass: {
            type: String,
            default: "",
        },
        // Flag indicating if there are more items to load.
        hasMore: {
            type: Boolean,
            default: true,
        },
        // Loading flag (covers both initial load and load-more).
        loading: {
            type: Boolean,
            default: false,
        },
        // Default maximum height (in pixels) of the menu.
        defaultMaxHeight: {
            type: Number,
            default: 300,
        },
        // Optional initial search query.
        search: {
            type: String,
            default: "",
        },
        // The threshold (in pixels) from the bottom to trigger loading more.
        scrollThreshold: {
            type: Number,
            default: 10,
        },
        // Nudge value (in pixels) to adjust vertical position.
        nudgeTop: {
            type: Number,
            default: 20,
        },
        // Whether the component is disabled.
        disabled: {
            type: Boolean,
            default: false,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        required: {
            type: Boolean,
            default: false,
        },
        autofocus: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            menu: false,
            internalMaxHeight: this.defaultMaxHeight,
            localSearch: this.search,
            debouncedSearch: null,
            menuWidth: null,
        };
    },
    computed: {
        // Returns true if loading is active.
        isLoading() {
            return this.loading;
        },
        // Style for the card (scrollable container).
        cardStyle() {
            return {
                maxHeight: this.internalMaxHeight + "px",
                overflowY: "auto",
                width: this.menuWidth ? this.menuWidth + "px" : "auto",
            };
        },
        // Computes the default text to display when no search is active.
        displayText() {
            if (this.localSearch !== "") {
                return this.localSearch;
            }
            if (this.value != null) {
                const selItem = this.items.find(
                    (item) => item[this.selectedKey] === this.value
                );
                if (selItem) {
                    return this.itemText(selItem);
                }
            }
            return "";
        },
        labelText() {
            return this.label || this.$translate("select_an_item");
        },
    },
    methods: {
        // Helper to extract plain text from VNodes.
        extractTextFromVNodes(nodes) {
            let text = "";
            if (nodes && Array.isArray(nodes)) {
                nodes.forEach((node) => {
                    if (node.children && Array.isArray(node.children)) {
                        text += this.extractTextFromVNodes(node.children);
                    } else if (node.text) {
                        text += node.text;
                    }
                });
            }
            return text;
        },
        // Simple debounce helper.
        debounce(func, wait) {
            let timeout;
            return function (...args) {
                const context = this;
                clearTimeout(timeout);
                timeout = setTimeout(() => func.apply(context, args), wait);
            };
        },
        // Called when the menu opens; calculates available space below the activator and sets menu width.
        onMenuChange(isOpen) {
            if (isOpen) {
                this.$nextTick(() => {
                    const activatorEl = this.$refs.activator.$el || this.$refs.activator;
                    const rect = activatorEl.getBoundingClientRect();
                    const spaceBelow = window.innerHeight - rect.bottom - 10;
                    this.internalMaxHeight =
                        spaceBelow > this.defaultMaxHeight
                            ? this.defaultMaxHeight
                            : spaceBelow;
                    this.menuWidth = rect.width;
                });
            }
        },
        // When an item is selected, emit events and clear the search query.
        selectItem(item) {
            this.$emit("input", item[this.selectedKey]);
            this.$emit("select", item);
            this.localSearch = "";
            // this.$emit("search-changed", "");
            this.menu = false;
        },
        // Clears the selection and search.
        clearSelection() {
            this.$emit("input", null);
            this.$emit("clear");
            this.localSearch = "";
            this.$emit("search-changed", "");
        },
        // Called when the search text changes.
        // If the menu is closed, open it; then debounce the search-changed event.
        onSearchChange(newVal) {
            this.$emit("input", null);
            if (!this.menu) {
                this.menu = true;
            }
            this.debouncedSearch(newVal);
        },
        // Scroll handler: if near the bottom, emit the "load-more" event.
        onScroll(e) {
            const el = e.target;
            if (
                el.scrollHeight - el.scrollTop - el.clientHeight <=
                this.scrollThreshold
            ) {
                if (!this.isLoading && this.hasMore) {
                    this.loadMore();
                }
            }
        },
        // Emit "load-more" event for the parent to load additional items.
        loadMore() {
            this.$emit("load-more");
        },
        // When the text field is clicked, select all its text.
        selectText() {
            this.$nextTick(() => {
                const input = this.$refs.activator.$el.querySelector("input");
                if (input) {
                    input.select();
                }
            });
        },
        focus() {
            this.$refs.activator.focus();
        },
    },
    watch: {
        // Sync localSearch with external search prop.
        search(newVal) {
            this.localSearch = newVal;
        },
        // Update internal max height if defaultMaxHeight changes.
        defaultMaxHeight(newVal) {
            this.internalMaxHeight = newVal;
        },
        menu(newVal) {
            if (newVal && this.search === "" && !this.products?.length) {
                this.$emit("load-more");
            }
        },
    },
    created() {
        // Initialize debounced search with a 300ms delay.
        this.debouncedSearch = this.debounce(function (val) {
            this.$emit("search-changed", val);
        }, 300);
    },
    i18n: {
        messages: {
            en: {
                select_an_item: "Select an item",
                loading_in_progress: "Loading...",
                no_results: "No results found",
            },
            fr: {
                select_an_item: "Sélectionnez un article",
                loading_in_progress: "Chargement en cours...",
                no_results: "Aucun résultat",
            },
        },
    },
};
</script>

<style scoped>
/* Adjust additional styles as needed */
</style>
