import axios from '@/plugins/axios';
import utils from '@/stores/utils';
import store from '@/stores/store';

const createState = () => ({
    create_tags: null,

    documents: [],
    documents_filters: { offset: 0, limit: 20, },
    documents_count: 0,
    documents_loaded: false,
    documents_loading: false,
    documents_loading_errors: null,

    document_saving: false,
    document_saving_errors: null,

    shared_with: null,
});

const mutations = {
    updateTags(state, params) {
        state.create_tags = params.create_tags;
        state.documents_filters = {
            offset: 0,
            limit: state.documents_filters.limit,
            tags: params.tags,
        };
        state.shared_with = params.shared_with;
    },
    updateDocuments(state, data) {
        state.documents = data?.results;
        state.documents_count = data?.count;
        state.documents_loading = false;
        state.documents_loading_errors = null;
    },
    updateDocumentsLoading(state, documents_loading) {
        state.documents_loading = documents_loading;
    },
    updateDocumentsLoadingErrors(state, documents_loading_errors) {
        state.documents_loading_errors = documents_loading_errors;
        state.documents_loading = false;
    },
    updateDocumentsLoaded(state, documents_loaded) {
        state.documents_loaded = documents_loaded;
    },
    updateDocumentSaving(state, document_saving) {
        state.document_saving = document_saving;
    },
    updateDocumentSavingErrors(state, document_saving_errors) {
        state.document_saving_errors = document_saving_errors;
    },

};

const actions = {

    deleteDocument({ commit, dispatch, state }, document) {
        return new Promise((resolve, reject) => {
            axios.delete(`/api/documents/${document.id}/`)
                .then(resolve)
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    reject(error);
                }).finally(() => {
                    dispatch('fetchDocuments');
                })
        });

    },

    uploadDocument({ commit, dispatch, state }, params) {
        let headers = { 'Content-Type': 'multipart/form-data' };
        let data = new FormData();

        data.append("file", params.file);
        state.create_tags.forEach((tag) => {
            data.append("tags", tag);
        })
        if (state.shared_with) {
            data.append('shared_with', state.shared_with);
            data.append('shared', true);
        }

        commit('updateDocumentSavingErrors', null);
        commit('updateDocumentSaving', true);

        return new Promise((resolve, reject) => {
            axios.post('/api/documents/', data, { headers })
                .then((response) => {
                    resolve({ document: response.data });
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit('updateDocumentSavingErrors', error.details);
                    reject(error);
                }).finally(() => {
                    dispatch('fetchDocuments');
                    commit('updateDocumentSaving', false);
                })
        });

    },


    fetchDocuments({ commit, dispatch, state }, params) {
        if (store.getters['session/current_user_permissions'].indexOf("core.view_document") == -1) {
            return;
        }

        commit('updateDocumentsLoading', true);
        commit('updateDocumentsLoadingErrors', null);

        return new Promise((resolve, reject) => {
            axios.get("/api/documents/", { params: state.documents_filters })
                .then((response) => {
                    commit('updateDocuments', response.data);
                    commit('updateDocumentsLoaded', true);
                    resolve(response);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit('updateDocumentsLoadingErrors', error.details);
                    reject(error);
                })
                .finally(() => {
                    commit('updateDocumentsLoading', false);
                });

        });
    },

    init({ commit, dispatch, state }, params) {
        commit('updateTags', params);
        commit('updateDocuments', null);
        commit('updateDocumentsLoaded', false);
    }
};


export default () => ({
    namespaced: true,
    state: createState(),
    mutations,
    actions
});