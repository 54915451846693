import axios from '@/plugins/axios';
import utils from '@/stores/utils';

const state = {
    choices: [],
};

const mutations = {
    updateChoices(state, choices) {
        state.choices = choices;
    },

    updateValidatingOrder(state, validating) {
        state.validating_order = validating;
    },
};

const getters = {
    productstrategy(state) {
        return state.choices.map((choice) => {
            return {
                source_product: choice.source_product,
                target_product: choice.target_product,
                action: choice.action,
            };
        });
    }
};

const actions = {

    async init({ commit }, params) {
        commit('updateChoices', params.productstrategy || []);
    },

    async setAction({ commit, state }, params) {
        const choices = JSON.parse(JSON.stringify(state.choices));
        const choice = choices.find((choice) => choice.source_product == params.product);
        choice.action = params.action;
        if (choice.action == 'create') {
            choice.target_product = null;
            choice.target_product_data = null;
        }
        commit('updateChoices', choices);
    },

    async setTargetProduct({ commit, state }, params) {
        const choices = JSON.parse(JSON.stringify(state.choices));
        const choice = choices.find((choice) => choice.source_product == params.product);
        choice.target_product = params.target_product?.id;
        choice.target_product_data = params.target_product;
        commit('updateChoices', choices);
    }
};


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
