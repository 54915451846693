<template>
    <div>
        <SearchObject
            :disabled="disabled"
            :value="value"
            @input="$emit('input', $event)"
            @select="$emit('select', $event)"
            :base-url="baseUrl"
            :label="label || $translate('select_product')"
            :additional-params="{ ordering: 'name', ...additionalParams }"
            :item-text="itemText"
            :readonly="readonly"
            :required="required"
            :autofocus="autofocus"
            ref="search"
        >
            <template slot="item" slot-scope="{ item }">
                <ProductName
                    class="my-1 text-body-3"
                    type="providerproduct"
                    :product="item"
                    :upc="true"
                    :inline-upc="true"
                />
            </template>
        </SearchObject>
    </div>
</template>

<script>
export default {
    name: "SearchProvider",
    props: {
        // The base URL to fetch items from.
        baseUrl: {
            type: String,
            default: "/api/products/",
        },
        // Additional GET parameters to include in the API request.
        additionalParams: {
            type: Object,
            default: () => ({}),
        },
        // Whether the component is disabled.
        disabled: {
            type: Boolean,
            default: false,
        },
        // v-model binding (selected item ID).
        value: {
            type: [String, Number],
            default: null,
        },
        // Label for the autocomplete.
        label: {
            type: String,
            default: "",
        },
        // Optional limit per request.
        limit: {
            type: Number,
            default: 20,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        required: {
            type: Boolean,
            default: false,
        },
        autofocus: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        itemText(item) {
            return `${item.upc} - ${item.name} - ${item.description}`;
        },
        focus() {
            this.$refs.search.focus();
        },
    },
    i18n: {
        messages: {
            en: {
                select_product: "Select a product",
            },
            fr: {
                select_product: "Sélectionnez un produit",
            },
        },
    },
};
</script>
