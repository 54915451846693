import * as Sentry from "@sentry/vue";

export default {
  install(Vue, options) {
    // If prod environment, use prod key, else don't track
    if (process.env.NODE_ENV == "production") {
      Sentry.init({
        Vue,
        dsn: "https://9d2e5cd207d2be4cdfdb6cb2e3bd5563@o160921.ingest.us.sentry.io/4508743214891008",
        integrations: [
          //Sentry.browserTracingIntegration({ router }),
          Sentry.replayIntegration(),
        ],
        // Tracing
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost", /^https:\/\/dashboard\.safetee\.io\/api/],
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
      });
    }
  }
};