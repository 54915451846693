import axios from '@/plugins/axios';
import qs from 'qs';
import utils from '@/stores/utils';

const state = {
    prospects: [],
    filters: { offset: 0, limit: 20, ordering: "name" },
    prospects_loading: false,
    prospects_count: 0,
    prospects_loading_errors: null,
    synchronizing_prospects: false,
    synchronizing_prospects_errors: null,
};

const mutations = {
    updateFilters(state, filters) {
        state.filters = filters;
    },
    updateProspects(state, prospects) {
        state.prospects = prospects;
    },
    updateProspectsCount(state, prospects_count) {
        state.prospects_count = prospects_count;
    },
    updateProspectsLoading(state, loading) {
        state.prospects_loading = loading;
    },
    updateProspectsLoadingErrors(state, errors) {
        state.prospects_loading_errors = errors;
    },
    updateSynchronizingProspects(state, synchronizing) {
        state.synchronize_prospects = synchronizing;
    },
    updateSynchronizingProspectsErrors(state, errors) {
        state.synchronizing_prospects_errors = errors;
    },
};

const actions = {

    async deleteProspect({ commit, dispatch, state }, prospect) {

        try {
            commit('updateProspectsLoading', true);
            commit('updateProspectsLoadingErrors', null);

            const response = await axios.delete(`/api/prospects/${prospect.id}/`);
            commit('updateProspectsLoading', false);
            dispatch('fetchProspects');
            return response;
        }
        catch (xhr_error) {
            let error = utils.handleError(xhr_error);
            commit('updateProspectsLoadingErrors', error.details);
            commit('updateProspectsLoading', false);
            throw error;
        }
    },


    fetchProspects({ commit, dispatch, state }, params) {
        commit('updateProspectsLoading', true);
        commit('updateProspectsLoadingErrors', null);

        return new Promise((resolve, reject) => {

            axios.get('/api/prospects/', {
                params: { ...state.filters },
            })
                .then((response) => {
                    commit('updateProspectsLoading', false);
                    commit('updateProspects', response.data.results);
                    commit('updateProspectsCount', response.data.count);
                    resolve(response);
                })
                .catch((xhr_error) => {
                    let error = utils.handleError(xhr_error);
                    commit('updateProspectsLoadingErrors', error.details);
                    commit('updateProspectsLoading', false);
                    reject(error);
                });
        });
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
