import axios from "@/plugins/axios";
import utils from "@/stores/utils";
import customers from "./submodules/customers";
import providers from "./submodules/providers";
import deliverysites from "./submodules/deliverysites";

const state = {
    delivery_type: "deliverysite",
    customer_id: null,

    providerorder: {},
    providerorder_saving: false,
    providerorder_saving_errors: {},

    providerorder_loading: false,
    providerorder_loading_errors: {},

    delivery_date: null,

    provider_id: null,
    provider: null,
    provider_loading: false,
    provider_loading_errors: {},

    deliverysite_id: null,
    deliverysite: null,
    deliverysite_loading: false,
    deliverysite_loading_errors: {},

    updating_providerorder_status: false,
};

const mutations = {
    updateProviderOrder(state, providerorder) {
        state.providerorder = JSON.parse(JSON.stringify(providerorder || {}));
        state.provider_id = providerorder.seller;
        state.customer_id = providerorder.recipient;
        state.deliverysite = providerorder.deliverysite_data;
        state.deliverysite_id = providerorder.deliverysite;
        state.delivery_date = providerorder.delivery_date;
        state.delivery_type = providerorder.recipient ? "customer" : "deliverysite";
    },
    updateProviderOrderSaving(state, saving) {
        state.providerorder_saving = saving;
    },
    updateProviderOrderSavingErrors(state, errors) {
        state.providerorder_saving_errors = errors;
    },
    updateProviderOrderLoading(state, loading) {
        state.providerorder_loading = loading;
    },
    updateProviderOrderLoadingErrors(state, errors) {
        state.providerorder_loading_errors = errors;
    },
    updateProvider(state, provider) {
        state.provider = provider;
        state.provider_id = provider.id;
        state.providerorder.seller = provider.id;
    },
    updateProviderId(state, provider_id) {
        state.provider_id = provider_id;
        state.providerorder.seller = provider_id;
    },

    updateDeliverySiteId(state, deliverysite_id) {
        state.deliverysite_id = deliverysite_id;
        state.providerorder.deliverysite = deliverysite_id;
    },
    updateDeliveryType(state, delivery_type) {
        state.delivery_type = delivery_type;
    },
    updateDeliverySiteLoadingErrors(state, errors) {
        state.deliverysite_loading_errors = errors;
    },
    updateDeliverySiteLoading(state, loading) {
        state.deliverysite_loading = loading;
    },
    updateDeliverySite(state, deliverysite) {
        state.deliverysite = deliverysite;
        state.deliverysite_id = deliverysite.id;
        state.providerorder.deliverysite = deliverysite.id;
    },

    updateProviderLoadingErrors(state, errors) {
        state.provider_loading_errors = errors;
    },
    updateProviderLoading(state, loading) {
        state.provider_loading = loading;
    },

    setDefaultDeliverySite(state) {
        if (
            !state.providerorder.deliverysite &&
            state.deliverysites.deliverysites.length == 1
        ) {
            state.deliverysite_id = state.deliverysites.deliverysites[0].id;
            state.providerorder.deliverysite = state.deliverysites.deliverysites[0].id;
        }
    },

    updateDeliveryDate(state, delivery_date) {
        state.providerorder.delivery_date = delivery_date;
        state.delivery_date = delivery_date;
    },
    updateCustomerId(state, customer_id) {
        state.customer_id = customer_id;
        state.providerorder.recipient = customer_id;
    },
    updateRecipient(state, recipient) {
        state.providerorder.recipient = recipient;
    },
};

const actions = {
    saveProviderOrder({ commit, dispatch, state }, params) {

        commit("updateProviderOrderSaving", true);
        commit("updateProviderOrderSavingErrors", {});

        let url = "/api/providerorders/";
        let method = axios.post;

        if (state.providerorder.id) {
            url = `/api/providerorders/${state.providerorder.id}/`;
            method = axios.put;
        }
        return new Promise((resolve, reject) => {
            method(url, state.providerorder)
                .then((response) => {
                    commit("updateProviderOrderSaving", false);
                    resolve(response);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);

                    commit("updateProviderOrderSaving", false);
                    commit("updateProviderOrderSavingErrors", error.details);
                    reject(error);
                })
                .finally(() => {
                    dispatch("session/fetchStats", null, { root: true });
                });
        });
    },

    setDeliverySiteId({ commit, dispatch, state }, deliverysite_id) {
        commit("updateDeliverySiteId", deliverysite_id);
    },

    async setDeliveryType({ commit, dispatch, state }, delivery_type) {
        commit("updateDeliveryType", delivery_type);
        commit("updateCustomerId", null);
        commit("updateDeliverySiteId", null);
        if (delivery_type == "deliverysite") {
            await dispatch("deliverysites/init", { filters: { limit: 1000 } });
            await dispatch("deliverysites/fetchDeliverySites");
            commit("setDefaultDeliverySite");
        } else {

            dispatch("customers/fetchCustomers");
            await dispatch("deliverysites/init")
        }
    },
    async setCustomerId({ commit, dispatch, state }, customer_id) {
        commit("updateCustomerId", customer_id);
        if (!customer_id) {
            commit("updateDeliverySiteId", null);
        }
        dispatch("initSources");
        dispatch("deliverysites/fetchDeliverySites").then((deliverysites) => {
            if (state.providerorder && deliverysites.results.length && !state.providerorder.deliverysite) {
                commit('updateDeliverySiteId', deliverysites.results[0].id);
            }
        });
    },

    initSources({ commit, dispatch, state }) {
        if (state.customer_id) {
            dispatch("deliverysites/init", { url: `/api/customers/${state.customer_id}/deliverysites/`, filters: { limit: 1000, ordering: "name" } });
        } else {
            dispatch("deliverysites/init", { url: `/api/deliverysites/`, filters: { limit: 1000, ordering: "name" } });
        }
    },


    init({ commit, dispatch, state }, providerorder) {
        dispatch("providers/init", { filters: { limit: 1000, ordering: 'name', exclude_profile: "operator" } });
        dispatch("providers/fetchProviders");
        dispatch("customers/init", { filters: { limit: 1000, ordering: 'name' } });
        dispatch("customers/fetchCustomers");

        commit("updateProviderOrder", providerorder);
        dispatch('initSources');
        dispatch('deliverysites/fetchDeliverySites');
    },

};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    modules: {
        customers: customers(),
        providers: providers(),
        deliverysites: deliverysites(),
    },
};
