<template>
    <div class="d-flex align-center customername" v-if="customer">
        <v-tooltip top>
            <template v-slot:activator="{ on }">
                <div v-on="customer.entity ? on : null">
                    <span v-if="!link">{{ customer.name }}</span>
                    <lookup v-else-if="lookup" object="customer" :params="lookup">
                        {{ customer.name }}
                    </lookup>
                    <router-link
                        v-else-if="routeExists('customer')"
                        :to="{
                            name: 'customer',
                            params: {
                                customer_id: customer.id,
                            },
                        }"
                    >
                        <Stroke :strike="customer.archived">
                            {{ customer.name }}
                        </Stroke>
                    </router-link>
                    <span v-else>{{ customer.name }}</span>

                    <v-icon
                        v-if="customer.entity"
                        :color="link ? 'primary' : null"
                        size="20"
                        class="ml-1 icon"
                        >mdi-link</v-icon
                    >
                </div>
            </template>
            <template v-if="customer.entity">
                {{ $translate("customerIsConnected") }}
            </template>
        </v-tooltip>
    </div>
</template>

<script>
import GlobalMixins from "@/mixins/global";

export default {
    mixins: [GlobalMixins],
    props: {
        customer: {
            required: false,
            type: Object,
        },
        lookup: {
            required: false,
            type: Object,
        },
        link: {
            required: false,
            type: Boolean,
            default: true,
        },
    },
    i18n: {
        messages: {
            en: {
                customerIsConnected: "This customer is connected",
            },
            fr: {
                customerIsConnected: "Ce client est connecté",
            },
        },
    },
};
</script>

<style scoped>
.customername .icon {
    /* opacity: 0;
    transition: opacity 0.3s; */
}
.customername:hover .icon {
    opacity: 1;
}
</style>
