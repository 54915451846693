import store from '@/stores/store';
import axios from '@/plugins/axios';
import utils from '@/stores/utils';
import documents from './submodules/documents';
import invoiceitems from './submodules/invoiceitems';
import qs from 'qs';

const state = {
    shipment: null,
    shipment_loading: false,
    shipment_loading_errors: null,
    shipment_saving: false,
    shipment_saving_errors: null,

    updating_shipment_status: false,
    assigning_shipment: false,

    deleting_shipment: false,
    deleting_shipment_errors: null,

    shipmentitem: false,
    shipmentitem_loading: false,
    shipmentitem_loading_errors: null,
    shipmentitem_saving: false,
    shipmentitem_saving_errors: null,
    shipmentitem_deleting: false,
    shipmentitem_deleting_errors: null,

    shipmentitems: null,
    shipmentitems_count: 0,
    shipmentitems_filters: { limit: 20 },
    shipmentitems_loaded: false,
    shipmentitems_loading: false,
    shipmentitems_loading_errors: null,
    shipmentitems_cancel_source: null,

    customers: [],
    customers_count: 0,
    customers_loading: false,
    customers_loading_errors: null,

    deliverysites: [],
    deliverysites_count: 0,
    deliverysites_loading: false,
    deliverysites_loading_errors: null,

    stocks: [],
    stocks_count: 0,
    stocks_loading: false,
    stocks_loading_errors: null,

    stockproducts: [],
    stockproducts_filters: { offset: 0, limit: 20, },
    stockproducts_count: 0,
    stockproducts_loaded: false,
    stockproducts_loading: false,
    stockproducts_loading_errors: null,
    stockproducts_cancel_source: null,

    events: [],
    events_filters: {},
    events_loading: false,
    events_loaded: false,
    events_loading_errors: null,
    events_count: 0,

    setting_shipment_readyness: false,

    shipment_stats: {},
    shipment_stats_loading: false,
    shipment_stats_loading_errors: null,
    shipment_stats_loaded: false,
};


const mutations = {
    updateShipment(state, shipment) {

        if (shipment && state.shipment && state.shipment.shipment_id == shipment.shipment_id) {
            state.shipment = shipment;
            return;
        }

        state.shipment = shipment;

        state.shipmentitems = [];
        state.shipmentitems_count = 0;
        state.shipmentitems_loaded = false;

        state.stockproducts = [];
        state.stockproducts_count = 0;
        state.stockproducts_loaded = false;

        state.events = [];
        state.events_count = 0;
        state.events_loaded = false;
        state.events_loading_errors = null;

        if (!shipment) {
            state.shipmentitems_filters = { offset: 0, limit: state.shipmentitems_filters.limit };
            state.stockproducts_filters = { offset: 0, limit: state.stockproducts_filters.limit };
            state.events_filters = { object_type: 'shipment', ordering: "event_date" };
            return;
        }

        state.shipmentitems_filters = { offset: 0, limit: state.shipmentitems_filters.limit };
        state.stockproducts_filters = { offset: 0, limit: state.stockproducts_filters.limit, stock: shipment.stock, exclude_shipment_products: shipment.shipment_id };
        state.events_filters = { object_type: 'shipment', object_id: shipment.shipment_id, ordering: "event_date" };

        if (state.shipmentitems_cancel_source) {
            state.shipmentitems_cancel_source.cancel("canceled");
            state.shipmentitems_cancel_source = null;
        }
        if (state.stockproducts_cancel_source) {
            state.stockproducts_cancel_source.cancel("canceled");
            state.stockproducts_cancel_source = null;
        }

    },
    updateShipmentLoading(state, loading) {
        state.shipment_loading = loading;
    },
    updateShipmentLoadingErrors(state, errors) {
        state.shipment_loading_errors = errors;
    },
    updateShipmentSaving(state, saving) {
        state.shipment_saving = saving;
    },
    updateShipmentSavingErrors(state, errors) {
        state.shipment_saving_errors = errors;
    },

    updateUpdatingShipmentStatus(state, status) {
        state.updating_shipment_status = status;
    },

    updateShipmentDeleting(state, deleting) {
        state.deleting_shipment = deleting;
    },
    updateShipmentDeletingErrors(state, errors) {
        state.deleting_shipment_errors = errors;
    },


    updateShipmentItem(state, shipmentitem) {
        state.shipmentitem = shipmentitem;
    },
    updateShipmentItemLoading(state, loading) {
        state.shipmentitem_loading = loading;
    },
    updateShipmentItemLoadingErrors(state, errors) {
        state.shipmentitem_loading_errors = errors;
    },
    updateShipmentItemSaving(state, saving) {
        state.shipmentitem_saving = saving;
    },
    updateShipmentItemSavingErrors(state, errors) {
        state.shipmentitem_saving_errors = errors;
    },
    updateShipmentItemDeleting(state, deleting) {
        state.shipmentitem_deleting = deleting;
    },
    updateShipmentItemDeletingErrors(state, errors) {
        state.shipmentitem_deleting_errors = errors;
    },


    updateShipmentItems(state, shipmentitems) {
        state.shipmentitems = shipmentitems;
        state.shipmentitems_loaded = true;
    },
    updateShipmentItemsCount(state, count) {
        state.shipmentitems_count = count;
    },
    updateShipmentItemsFilters(state, shipmentitems_filters) {
        state.shipmentitems_filters = Object.assign(
            {
                limit: state.shipmentitems_filters.limit || 20,
                ordering: state.shipmentitems_filters.ordering,
            },
            shipmentitems_filters);
    },
    updateShipmentItemsLoading(state, loading) {
        state.shipmentitems_loading = loading;
    },
    updateShipmentItemsLoadingErrors(state, errors) {
        state.shipmentitems_loading_errors = errors;
    },
    updateShipmentItemsCancelSource(state, shipmentitems_cancel_source) {
        state.shipmentitems_cancel_source = shipmentitems_cancel_source;
    },



    updateCustomers(state, customers) {
        state.customers = customers;
    },
    updateCustomersCount(state, count) {
        state.customers_count = count;
    },
    updateCustomersLoading(state, loading) {
        state.customers_loading = loading;
    },
    updateCustomersLoadingErrors(state, errors) {
        state.customers_loading_errors = errors;
    },

    updateDeliverySites(state, deliverysites) {
        state.deliverysites = deliverysites;
    },
    updateDeliverySitesCount(state, count) {
        state.deliverysites_count = count;
    },
    updateDeliverySitesLoading(state, loading) {
        state.deliverysites_loading = loading;
    },
    updateDeliverySitesLoadingErrors(state, errors) {
        state.deliverysites_loading_errors = errors;
    },

    updateStocks(state, stocks) {
        state.stocks = stocks;
    },
    updateStocksLoading(state, loading) {
        state.stocks_loading = loading;
    },
    updateStocksLoadingErrors(state, errors) {
        state.stocks_loading_errors = errors;
    },
    updateStocksCount(state, count) {
        state.stocks_count = count;
    },

    updateStockProducts(state, stockproducts) {
        state.stockproducts = stockproducts;
        state.stockproducts_loaded = true;
    },
    updateStockProductsFilters(state, stockproducts_filters) {
        state.stockproducts_filters = stockproducts_filters;
    },
    updateStockProductsCount(state, stockproducts_count) {
        state.stockproducts_count = stockproducts_count;
    },
    updateStockProductsLoading(state, loading) {
        state.stockproducts_loading = loading;
    },
    updateStockProductsLoadingErrors(state, errors) {
        state.stockproducts_loading_errors = errors;
    },
    updateStockProductsCancelSource(state, source) {
        state.stockproducts_cancel_source = source;
    },

    updateEvents(state, events) {
        state.events = events;
    },
    updateEventsLoading(state, events_loading) {
        state.events_loading = events_loading;
    },
    updateEventsLoadingErrors(state, events_loading_errors) {
        state.events_loading_errors = events_loading_errors;
    },
    updateEventsCount(state, events_count) {
        state.events_count = events_count;
    },
    updateAssigningShipment(state, assigning_shipment) {
        state.assigning_shipment = assigning_shipment;
    },

    updateSettingShipmentReadyness(state, setting_shipment_readyness) {
        state.setting_shipment_readyness = setting_shipment_readyness;
    },

    updateShipmentStats(state, shipment_stats) {
        state.shipment_stats = shipment_stats;
    },
    updateShipmentStatsLoading(state, loading) {
        state.shipment_stats_loading = loading;
    },
    updateShipmentStatsLoadingErrors(state, errors) {
        state.shipment_stats_loading_errors = errors;
    },
    updateShipmentStatsLoaded(state, loaded) {
        state.shipment_stats_loaded = loaded;
    },

};

const actions = {

    async fetchEvents({ commit, state }) {
        if (store.getters['session/current_user_permissions'].indexOf("core.view_event") == -1) {
            return;
        }
        commit('updateEventsLoading', true);

        try {
            const response = await axios.get('/api/events/', { params: state.events_filters });
            commit('updateEvents', response.data.results);
            commit('updateEventsCount', response.data.count);
            return response;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            commit('updateEventsLoadingErrors', error.details);
            throw error;
        } finally {
            commit('updateEventsLoading', false);
        }
    },

    async fetchShipment({ commit, dispatch, state }, params) {
        commit('updateShipmentLoading', true);
        commit('updateShipmentLoadingErrors', null);

        try {
            const response = await axios.get(`/api/shipments/${state.shipment.shipment_id}/`);
            commit('updateShipmentLoading', false);
            commit('updateShipment', response.data);
            dispatch('fetchShipmentStats');
            return response;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            commit('updateShipmentLoading', false);
            commit('updateShipmentLoadingErrors', error.details);
            throw error;
        }
    },

    async setShipmentReadyness({ commit, dispatch, state }, params) {
        commit('updateSettingShipmentReadyness', true);

        try {
            const url = `/api/shipments/${state.shipment.shipment_id}/ready/`;
            const response = await axios.put(url, { ready: params.ready })
            await dispatch('fetchShipment');
            dispatch('fetchEvents');
            dispatch('session/fetchStats', null, { root: true });

            return response;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            throw error;
        } finally {
            commit('updateSettingShipmentReadyness', false);
        }
    },

    async setShipmentStatus({ commit, dispatch, state }, params) {
        commit('updateUpdatingShipmentStatus', true);

        try {
            const url = `/api/shipments/${state.shipment.shipment_id}/status/`;
            const response = await axios.put(url, { status: params.status })
            await dispatch('fetchShipment');
            dispatch('invoiceitems/fetchInvoiceItems');
            dispatch('fetchShipmentItems');
            dispatch('fetchEvents');
            dispatch('session/fetchStats', null, { root: true });
            return response;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            throw error;
        } finally {
            commit('updateUpdatingShipmentStatus', false);
        }
    },

    async deleteShipment({ commit, dispatch }, params) {
        try {
            commit('updateShipmentDeleting', true);
            commit('updateShipmentDeletingErrors', null);
            const url = `/api/shipments/${params.instance.shipment_id}/`;
            const response = await axios.delete(url);
            commit('updateShipmentDeleting', false);
            return response;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            commit('updateShipmentDeleting', false);
            if (error.details) {
                commit('updateShipmentDeletingErrors', error.details);
            }
            throw error;
        } finally {
            dispatch('session/fetchStats', null, { root: true });
        }
    },
    async deleteShipmentItem({ commit, dispatch, state }, params) {
        commit('updateShipmentItemDeleting', true);
        commit('updateShipmentItemDeletingErrors', null);

        try {
            const url = `/api/shipmentitems/${params.instance.id}/`;
            const response = await axios.delete(url);
            commit('updateShipmentItemDeleting', false);
            await dispatch('fetchShipment');
            await dispatch('fetchShipmentItems');
            await dispatch('fetchStockProducts');
            return response;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            await dispatch('fetchShipment');
            await dispatch('fetchShipmentItems');
            await dispatch('fetchStockProducts');
            commit('updateShipmentItemDeleting', false);
            commit('updateShipmentItemDeletingErrors', error.details);
            throw error;
        }
    },

    async setShipmentItemStatus({ commit, dispatch, state }, params) {
        const url = `/api/shipmentitems/${params.shipmentitem.id}/status/`;

        try {
            const response = await axios.put(url, {
                status: params.status,
                received_count: params.received_count,
            });
            await dispatch('fetchShipment');
            await dispatch('fetchShipmentItems');
            dispatch('fetchEvents');
            return response;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            throw error;
        }
    },

    async fetchShipmentItems({ commit, dispatch, state }, params) {
        commit('updateShipmentItemsLoading', true);
        commit('updateShipmentItemsLoadingErrors', null);

        if (state.products_cancel_source) {
            state.products_cancel_source.cancel("canceled");
        }
        commit('updateShipmentItemsCancelSource', axios.CancelToken.source());
        try {
            const response = await axios.get("/api/shipmentitems/", {
                params: Object.assign({
                    shipment: state.shipment.shipment_id,
                    stock: state.shipment.stock,
                }, state.shipmentitems_filters
                ),
                cancelToken: state.shipmentitems_cancel_source?.token,
            });

            commit('updateShipmentItems', response.data.results);
            commit('updateShipmentItemsCount', response.data.count);
            commit('updateShipmentItemsCancelSource', null);
            return response;
        } catch (xhr_error) {
            if (axios.isCancel(xhr_error)) {
                return;
            }
            const error = utils.handleError(xhr_error);
            commit('updateShipmentItemsLoadingErrors', error.details);
            throw error;
        } finally {
            commit('updateShipmentItemsLoading', false);
        }
    },


    async fetchStockProducts({ commit, dispatch, state }, params) {
        commit('updateStockProductsLoading', true);
        commit('updateStockProductsLoadingErrors', null);

        if (state.stockproducts_cancel_source) {
            state.stockproducts_cancel_source.cancel("canceled");
        }

        const cancelSource = axios.CancelToken.source();
        commit('updateStockProductsCancelSource', cancelSource);

        try {
            const response = await axios.get('/api/stockproducts/', {
                params: state.stockproducts_filters,
                cancelToken: state.stockproducts_cancel_source?.token,
            });

            commit('updateStockProductsLoading', false);
            commit('updateStockProducts', response.data.results);
            commit('updateStockProductsCount', response.data.count);
            commit('updateStockProductsCancelSource', null);
            return response;
        } catch (xhr_error) {
            if (axios.isCancel(xhr_error)) {
                return;
            }
            let error = utils.handleError(xhr_error);
            commit('updateStockProductsLoadingErrors', error.details);
            commit('updateStockProductsLoading', false);
            commit('updateStockProductsCancelSource', null);
            throw error;
        }
    },

    async assignShipment({ commit, dispatch, state }, params) {
        const url = `/api/shipments/${state.shipment.shipment_id}/assign/`;
        commit('updateAssigningShipment', true);

        try {
            const response = await axios.put(url, { user: params.user });
            dispatch('fetchShipment');
            return response;
        } catch (xhr_error) {
            throw utils.handleError(xhr_error);
        } finally {
            commit('updateAssigningShipment', false);
            dispatch('fetchEvents');
            dispatch('session/fetchStats', null, { root: true });
        }
    },

    async fetchShipmentStats({ commit, dispatch, state }, params) {
        commit('updateShipmentStatsLoading', true);
        commit('updateShipmentStatsLoadingErrors', null);
        try {
            const response = await axios.get(`/api/shipments/${state.shipment.shipment_id}/stats/`);
            commit('updateShipmentStats', response.data);
            return response;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            commit('updateShipmentStatsLoadingErrors', error.details);
            throw error;
        } finally {
            commit('updateShipmentStatsLoading', false);
        }
    },

    exportShipmentItemsAsCSV({ commit, dispatch, state }, params) {
        let filters = {
            format: 'csv',
            limit: 10000,
            shipment: state.shipment.shipment_id,
        };
        const queryparams = qs.stringify(filters, { arrayFormat: 'repeat' });
        window.open("/api/shipmentitems/?" + queryparams);
    },

    update({ commit, dispatch, state }, params) {
        dispatch('fetchShipment');
        dispatch('fetchShipmentStats');
        dispatch('fetchShipmentItems');
        dispatch('fetchEvents');
        dispatch('documents/fetchDocuments');
        dispatch('invoiceitems/fetchInvoiceItems');
        dispatch('fetchStockProducts');
    },

    init({ commit, dispatch, state }, params) {
        commit('updateShipment', params.shipment);
        dispatch('fetchShipmentStats');
        dispatch('fetchEvents');
        dispatch('fetchShipmentItems');

        dispatch('invoiceitems/init', { shipment: params.shipment, url: `/api/shipments/${params.shipment.shipment_id}/invoiceitems/`, filters: { billed: params.shipment.customer } });
        dispatch('invoiceitems/fetchInvoiceItems');

        if (!params.shipment.ready) {
            dispatch('fetchStockProducts');
        }

        let shared_with = null;
        if (store.getters['session/current_entity_id'] !== params.shipment.initiator) {
            shared_with = params.shipment.initiator;
        }
        dispatch('documents/init', {
            tags: `shipment:${params.shipment.shipment_id}`,
            shared_with,
            create_tags: [
                `shipment:${params.shipment.shipment_id}`
            ]
        });
        dispatch('documents/fetchDocuments');
    },


};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    modules: {
        documents: documents(),
        invoiceitems: invoiceitems(),
    }
};
