import axios from "@/plugins/axios";
import utils from "@/stores/utils";

const state = {
    prospect: null,
    prospect_saving: false,
    prospect_saving_errors: {},
};

const mutations = {
    updateProspect(state, prospect) {
        if (state.prospect && prospect && state.prospect.id == prospect.id) {
            state.prospect = prospect;
            return;
        }
        state.prospect = prospect;
    },
    updateProspectSaving(state, saving) {
        state.prospect_saving = saving;
    },
    updateProspectSavingErrors(state, errors) {
        state.prospect_saving = false;
        state.prospect_saving_errors = errors;
    },
};

const actions = {

    saveProspect({ commit, dispatch, state }, params) {

        commit("updateProspectSaving", true);

        let url = "/api/prospects/";
        let method = axios.post;

        if (params.instance.id) {
            url = `/api/prospects/${params.instance.id}/`;
            method = axios.put;
        }
        return new Promise((resolve, reject) => {
            method(url, params.instance)
                .then((response) => {
                    commit("updateProspectSaving", false);
                    resolve(response);
                })
                .catch((xhr_error) => {
                    let errors = utils.handleError(xhr_error);
                    commit("updateProspectSavingErrors", errors.details);
                    reject(errors);
                })
                .finally(() => {
                    dispatch("session/fetchStats", null, { root: true });
                });
        });
    },

    init({ commit, dispatch, state }, params) {
        commit("updateProspectSavingErrors", {});
        commit("updateProspect", JSON.parse(JSON.stringify(params.prospect)));
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
